import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from "react-tooltip";
import { closePayloadDialog } from "../../actions/index"
import './PayloadDialog.css';

const PayloadDialog = () => {
    const showPayloadDialog = useSelector(state => state.showPayloadDialog);
    const dispatch = useDispatch();

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    const close = () => {
        dispatch(closePayloadDialog());
    }

    return (
        showPayloadDialog?
        <div className="payload-dialog-overlay" onClick={(e) => stopPropagation(e)}>
            <div className="dialog-bottom-container">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modal-title-1">Operating payload</h5>
                        <i className="material-icons" data-for="main" data-tip="While the payload is operating the application controls are blocked.<br />To retain control, 
                            either wait for the payload operation to finnish,<br />or stop the payload in mid operation" data-iscapture="true">info</i>
                        <ReactTooltip
                            id="main"
                            place={"top"}
                            type={"dark"}
                            effect={"solid"}
                            multiline={true}
                        />
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => close()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* <p></p> */}
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : null
    )
}

export default PayloadDialog;