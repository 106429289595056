import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toggleNotificationLog } from "../../actions/index.js";
import Notifications from "../../js/notifications";
import './NotificationLog.css'

const NotificationLog = () => {
    const { t } = useTranslation();
    const showNotificationLog = useSelector(state => state.showNotificationLog);
    const [list, setList] = useState(Notifications.getNotifications());
    const dispatch = useDispatch();

    useEffect(() => {
        const notificationSubscription = Notifications.notificationObservable.subscribe((newList) => {
            setList([...newList]);
        });

        return () => {
            notificationSubscription.unsubscribe();
        }
    }, [])

    const getIcon = (type) => {
        switch (type) {
            case "INFO":
                return 'info';
            case "WARN":
                return 'warning'
            case "ERROR":
                return 'error'
            case "SUCCESS":
                return 'check_circle'
            default:
                return 'info';
        }
    }

    return (
        showNotificationLog?
        <div className="notification-container">
            <div className="notification-content">
                {(list && list.length > 0)?
                    <ul className="list-group">
                        {list.map(item => (
                            <li key={item.timestamp} className="list-group-item list-item-left-control notification-item">
                                <i className={"modus-icon material-icons " + getIcon(item.type)}>{getIcon(item.type)}</i>
                                <div>
                                    <h5 className="item-title">{item.title}</h5>
                                    <span>{item.msg}</span>
                                </div>
                                <span className="timestamp-span">{item.timestamp}</span>
                            </li>
                        ))}
                    </ul>
                : 
                <li className="list-placeholder-container list-group-item list-item-left-control mt-5">
                    <i className="material-icons">circle_notifications</i>
                    <p className="m-0">{t("NO_NOTIFICATIONS_RECIEVED")}</p>
                </li>
                }
                <div className="close-container">
                    <button className="btn btn-icon-only btn-text-dark d-flex justify-content-center align-items-center"
                        onClick={() => dispatch(toggleNotificationLog())}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
            </div>
        </div>
        : null
    )
}

export default NotificationLog;