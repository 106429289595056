export const initialState = () => {
    return {
        type: 'INITIAL_STATE'
    }
}

export const loginAction = () => {
    return {
        type: 'LOGIN'
    };
};

export const logoutAction = () => {
    return {
        type: 'LOGOUT'
    };
};

export const connect = () => {
    return {
        type: 'CONNECTED'
    };
};

export const disconnect = () => {
    return {
        type: 'DISCONNECTED'
    };
};

export const toggleLog = () => {
    return {
        type: 'TOGGLE_LOG'
    };
};

export const toggleSidebar = () => {
    return {
        type: 'TOGGLE_SIDEBAR'
    };
};

export const gatheringIceComplete = () => {
    return {
        type: 'COMPLETE'
    };
};

export const gatheringIce = () => {
    return {
        type: 'GATHERING'
    };
};

export const switchPanelView = (viewNumber) => {
    return {
        type: 'SWITCH_PANEL_VIEW',
        view: viewNumber
    };
};

export const freezePanel = (id) => {
    return {
        type: 'FREEZE_PANEL',
        panelId: id
    };
};

export const unfreezePanel = (id) => {
    return {
        type: 'UNFREEZE_PANEL',
        panelId: id
    };
};

export const toggleTouchControls = () => {
    return {
        type: 'TOGGLE_TOUCH_CONTROLS'
    };
};

export const toggleNotificationLog = () => {
    return {
        type: 'TOGGLE_NOTIFICATION_LOG'
    };
};

export const openPayloadDialog = () => {
    return {
        type: 'OPEN_PAYLOAD_DIALOG'
    };
};

export const closePayloadDialog = () => {
    return {
        type: 'CLOSE_PAYLOAD_DIALOG'
    };
};

export const toggleAdminView = () => {
    return {
        type: 'TOGGLE_ADMIN_VIEW'
    };
};

export const setAdminViewContext = (adminView) => {
    return {
        type: 'SET_ADMIN_VIEW_CONTEXT',
        view: adminView
    };
};

export const setActiveCompany = (company) => {
    return {
        type: 'SET_ACTIVE_COMPANY',
        activeCompany: company
    };
};

export const setPermissionLevel = (level) => {
    return {
        type: 'SET_PERMISSION_LEVEL',
        permissionLevel: level
    };
}