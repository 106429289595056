import React, { useRef, useEffect, useState, useCallback }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RobotConnection from "../../../js/robotconnection.js";
import RobotMovement from "../../../js/robotmovement.js";
import { secondsToTimeString } from "../../../js/utils.js";
import { toggleLog, switchPanelView } from "../../../actions/index.js";
import './Sidebar.css';
import PanelImg1 from "../../../assets/pannel_1.svg";
import PanelImgVertical2 from "../../../assets/pannel_2_vertical.svg";
import PanelImgHorizontal2 from "../../../assets/pannel_2_horizontal.svg"
import PanelImg4 from "../../../assets/pannel_4.svg";

const Sidebar = () => {
    const showSidebarView = useSelector(state => state.showSideBar);

    return (
        showSidebarView?
            <div className="sidebar-container">
                <div className="sidebar-content">
                    < InfoView />
                    < LogView/>
                </div>
            </div>
        : ""
    )
};

const InfoView = () => {
    const { t } = useTranslation();
    const [informationItems, setInformationItems] = useState();
    const [robotMuted, setRobotMutedState] = useState(RobotConnection.getRobotMuted());
    const [userMuted, setUserMutedState] = useState(RobotConnection.getUserMuted());
    const showLogView = useSelector(state => state.showLog);
    const powerMeter = useRef(null);

    function toggleRobotVolume() {
        RobotConnection.setRobotMuted();
    };

    function toggleUserVolume() {
        RobotConnection.setUserMuted();
    }

    const getInformationHTML = useCallback((information) => {
        return (
            <div>
                <div className="info-item">
                    <p className="info-property">{t("CONNECTION_STATUS")}:</p>
                    <p className="info-value">{t(information.connectionState)}</p>
                </div>
                <div className="info-item">
                    <p className="info-property">{t("POWER_STATUS")}:</p>
                    <p className="info-value">{t(information.powerStateString)}</p>
                </div>
                <div className="battery-item">
                    <div className="battery-icon-container">
                        <i className="material-icons">battery_full</i>
                        <div className="power-meter" ref={powerMeter}></div>
                    </div>
                    <div className="battery-text-container">
                        <p className="battery-text">{information.battery}%</p>
                        <p className="battery-text">{secondsToTimeString(information.batteryLifeTime)}</p>
                    </div>
                </div>
            </div>
        )
    }, [t]);

    useEffect(() => {
        const loadedInformation = getInformationHTML(RobotConnection.getRobotData());
        setInformationItems(loadedInformation);

        const robotDataSubscription = RobotConnection.robotDataObservable.subscribe(newData => {
            const information = getInformationHTML(newData);

            if (powerMeter.current !== null) {
                powerMeter.current.style.height = ((1 - (newData.battery / 100)) * 16).toString() + "px";
            }
            // console.log(newData);
            setInformationItems(information);
        });

        const videoDataSubscription = RobotConnection.videoDataObservable.subscribe(newData => {
            setRobotMutedState(newData.robotMuted);
            setUserMutedState(newData.userMuted);
        });

        return () => {
            robotDataSubscription.unsubscribe();
            videoDataSubscription.unsubscribe();
        };
    }, [getInformationHTML]);

    return (
        <div id="robotInformation" className={"info-container " + (!showLogView? "info-container-full" : "info-container-70")}>
            <h3 className="sidebar-header">
                <span>{t("ROBOT_INFORMATION")}</span>
                <div className="video-tools-container">
                    <div className="video-tools-icon-container" onClick={() => toggleRobotVolume()}>
                        <i className="material-icons">{robotMuted? 'volume_off' : 'volume_up'}</i>
                    </div>
                    <div className="video-tools-icon-container" onClick={() => toggleUserVolume()}>
                        <i className="material-icons">{userMuted? 'mic_off' : 'mic'}</i>
                    </div>
                </div>
            </h3>
            <div className="log-container">
                {informationItems}
                < KeyBoard />
            </div>
        </div>
    )
};

const KeyBoard = () => {
    const { t } = useTranslation();
    const [robotData, setRobotData] = useState(RobotConnection.getRobotData());
    const dispatch = useDispatch();

    useEffect(() => {
        const robotDataSubscription = RobotConnection.robotDataObservable.subscribe(newData => {
            setRobotData(newData);
        });

        return () => {
            robotDataSubscription.unsubscribe();
        };
    })

    const togglePower = () => {
        RobotConnection.sendCommand('P');
        RobotMovement.addToUsedCommands('P');
    }

    const toggleState = () => {
        if (robotData.robotStanding) {
            RobotConnection.sendSit();
        } else {
            RobotConnection.sendStand();
        }
    }

    const toggleDock = () => {
        RobotConnection.sendCommand('y');
        RobotMovement.addToUsedCommands('y');
    }

    const togglePayload = () => {
        RobotConnection.sendCommand('0');
        RobotMovement.addToUsedCommands('0');
    }

    const keyClicked = (key) => {
        RobotConnection.sendCommand(key);
        RobotMovement.addToUsedCommands(key);
    }

    return(
        <div className="controls-container">
            <p className="panel-control-text">{t("VIDEO_PANEL_LAYOUT")}:</p>
            <div className="panel-control-container">
                <button className="btn btn-primary" onClick={() => dispatch(switchPanelView(1))}>
                    <img className="svgIcon" src={PanelImg1} alt=""/>
                </button>
                <button className="btn btn-primary" onClick={() => dispatch(switchPanelView(2))}>
                    <img className="svgIcon" src={PanelImgVertical2} alt=""/>
                </button>
                <button className="btn btn-primary" onClick={() => dispatch(switchPanelView(1.99))}>
                    <img className="svgIcon" src={PanelImgHorizontal2} alt=""/>
                </button>
                <button className="btn btn-primary" onClick={() => dispatch(switchPanelView(4))}>
                    <img className="svgIcon" src={PanelImg4} alt=""/>
                </button>
            </div>
            <button className={"btn " + (robotData.powerState? "btn-primary" : "btn-outline-primary")} onClick={() => togglePower()}>
                {robotData.powerState? t('POWER_OFF'): t('POWER_ON')}</button>
            <button className={"btn " + (robotData.robotStanding? "btn-primary" : "btn-outline-primary")} onClick={() => toggleState()}
                disabled={!robotData.powerState}>
                {robotData.robotStanding? t('SIT'): t('STAND')}</button>
            <button className="btn btn-primary" onClick={() => toggleDock()}
                disabled={!robotData.powerState}>
                {t("DOCK")}</button>
            <button className="btn btn-primary" onClick={() => togglePayload()}
                disabled={!robotData.powerState}>
                {t("OPERATE_PAYLOAD")}</button>
            <div className="keyboard-container">
                <div className="keyboard-key" onClick={() => keyClicked('q')}>
                    <i className="material-icons keyboard-icon">rotate_left</i>
                    <span className="keyboard-text">q</span>
                </div>
                <div className="keyboard-key" onClick={() => keyClicked('w')}>
                    <i className="material-icons keyboard-icon">arrow_upward</i>
                    <span className="keyboard-text">w</span>
                </div>
                <div className="keyboard-key" onClick={() => keyClicked('e')}>
                    <i className="material-icons keyboard-icon">rotate_right</i>
                    <span className="keyboard-text">e</span>
                </div>
                <div className="keyboard-key" onClick={() => keyClicked('a')}>
                    <i className="material-icons keyboard-icon">arrow_back</i>
                    <span className="keyboard-text">a</span>
                </div>
                <div className="keyboard-key" onClick={() => keyClicked('s')}>
                    <i className="material-icons keyboard-icon">arrow_downward</i>
                    <span className="keyboard-text">s</span>
                </div>
                <div className="keyboard-key" onClick={() => keyClicked('d')}>
                    <i className="material-icons keyboard-icon">arrow_forward</i>
                    <span className="keyboard-text">d</span>
                </div>
            </div>
        </div>
        
    )
}

const LogView = () => {
    const { t } = useTranslation();
    const [logItems, setLogItems] = useState();
    const showLogView = useSelector(state => state.showLog);
    const dispatch = useDispatch();

    useEffect(() => {
        const loadedItems = RobotMovement.getCommands().map((command, index) =>
            <li key={index}>
                {command[1] + ": "}<span className="log-command">{RobotMovement.commandDesc[command[0]]}</span>
            </li>
        );
        setLogItems(loadedItems);

        const commandsSubscription = RobotMovement.commandObservable.subscribe(commands => {
            const logItems = commands.map((command, index) =>
                <li key={index}>
                    {command[1] + ": "}<span className="log-command">{RobotMovement.commandDesc[command[0]]}</span>
                </li>
            );
            setLogItems(logItems);
        });

        return () => {
            commandsSubscription.unsubscribe();
        };
    }, []);

    return (
        <div className={"command-log-container " + (showLogView? "command-log-container-visible" : "")}>
            <h3 className="sidebar-header">
                <span>{t("COMMAND_LOG")}</span>
                <div className="header-toggle-icon" onClick={() => dispatch( toggleLog() )}>
                    <i className="material-icons">{showLogView? 'expand_more': 'expand_less'}</i>
                </div>
            </h3>
            {showLogView?
                <div id="instructionLog" className="log-container">
                    {logItems}
                </div>
                : ""
            }
            
        </div>
    )
}

export default Sidebar;