import {signIn, signOut, IRedirect, ISession} from '@fulcrum/lib/trimble-services';

export async function login(): Promise<ISession> {
    const result = await signIn({
        identityRedirectUri: "http://trimblespot.eastus.cloudapp.azure.com/",
        identityClientApp: "TrimbleConnectSpotWeb",
        identityClientId: "b8e2fa2e-5fd0-45d3-b757-89e1d2a94b36",
        identityClientKey: "DJzL8oDubdUrmLhiAEE6TWUl_fca",
        connectProvider: "//app.connect.trimble.com",
        identityProvider: "https://id.trimble.com"
    }) as ISession & IRedirect;
    if (result.accessToken)
        return result;
    window.location.href = result.uri;
    
}

export async function logout(): Promise<ISession> {
    const result = await signOut() as ISession & IRedirect;
    window.location.href = result.uri;
    return result;
}
