import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "ROBOT_INFORMATION": "Robot Information",
            "POWER_STATUS": "POWER STATUS",
            "STATE_UNKNOWN": "Unknown",
            "STATE_OFF": "Off",
            "STATE_ON": "On",
            "STATE_POWERING_ON": "Powering On",
            "STATE_POWERING_OFF": "Powering Off",
            "STATE_ERROR": "Error",
            "CONNECTION_STATUS": "CONNECTION STATUS",
            "VIDEO_PANEL_LAYOUT": "VIDEO PANEL LAYOUT",
            "POWER_ON": "Power On",
            "POWER_OFF": "Power Off",
            "SIT": "Sit",
            "STAND": "Stand",
            "DOCK" : "Dock",
            "OPERATE_PAYLOAD": "Operate Payload",
            "COMMAND_LOG": "Command Log",
            "new": "New",
            "connecting": "Connecting",
            "connected": "Connected",
            "disconnected": "Disconnected",
            "failed": "Failed",
            "closed": "Closed",
            "ADD_SERIAL" : "Connect to serial",
            "LOGOUT": "Logout",
            "CONNECT": "Connect",
            "DISCONNECT": "Disconnect",
            "CONNECTING_TO_ROBOT": "Connecting to SPOT",
            "ptz": "PTZ Camera",
            "spot_front": "Spot Front Camera",
            "spot_right": "Spot Right Camera",
            "spot_back": "Spot Back Camera",
            "spot_left": "Spot Left Camera",
            "none": "No Camera",
            "ROTATE_PTZ": "Rotate PTZ",
            "ZOOM_PTZ": "Zoom PTZ",
            "MOVE_ROBOT": "Move Robot",
            "ROTATE_ROBOT": "Rotate Robot",
            "NO_ROBOT_CONNECTIONS" : "No robots have been connected to yet",
            "NO_NOTIFICATIONS_RECIEVED": "No notifications have been recieved yet",
            "NOTIFICATIONS": "Notifications",
        }
    }
//   fr: {
//     translation: {
//       "Welcome to React": "Bienvenue à React et react-i18next"
//     }
//   }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

  export default i18n;