import React, { useRef, useEffect }  from 'react';
import RobotModel from "../../../js/robotmodel";

const WebGLModelPanel = () => {
    const ref = useRef(null)

    useEffect(() => {
        RobotModel.init(ref.current);
    }, []);
    
    return (
        <div ref={ref} className="viewer-container"></div>
    )
}

export default WebGLModelPanel;