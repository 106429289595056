export const logReducer = (state = false, action) => {
    switch (action.type) {
        case "TOGGLE_LOG":
            return state = !state;
        case "INITIAL_STATE":
            return state = false;
        default:
            return state;
    }
}

export const sidebarReducer = (state = true, action) => {
    switch (action.type) {
        case "TOGGLE_SIDEBAR":
            return state = !state;
        case "INITIAL_STATE":
            return state = true;
        default:
            return state;
    }
}

export const panelViewReducer = (state = 1, action) => {
    switch (action.type) {
        case "SWITCH_PANEL_VIEW":
            return state = action.view;
        case "INITIAL_STATE":
            return state = 1;
        default:
            return state;
    }
}

export const frozenPanelReducer = (state = { items: [] }, action) => {
    switch (action.type) {
        case "FREEZE_PANEL":
            return {
                ...state,
                items: [...state.items, action.panelId]
            }
        case "UNFREEZE_PANEL":
            return {
                ...state,
                items: state.items.filter(item => item !== action.panelId)
            }
        case "INITIAL_STATE":
            return {
                ...state,
                items: []
            }
        default:
            return state;
    }
}

export const touchControlsReducer = (state = false, action) => {
    switch (action.type) {
        case "TOGGLE_TOUCH_CONTROLS":
            return state = !state;
        case "INITIAL_STATE":
            return state = false;
        default:
            return state;
    }
}

export const notificationLogReducer = (state = false, action) => {
    switch (action.type) {
        case "TOGGLE_NOTIFICATION_LOG":
            return state = !state;
        case "INITIAL_STATE":
            return state = false;
        default:
            return state;
    }
}

export const payloadDialogReducer = (state = false, action) => {
    switch (action.type) {
        case "OPEN_PAYLOAD_DIALOG":
            return state = true;
        case "CLOSE_PAYLOAD_DIALOG":
            return state = false;
        case "INITIAL_STATE":
            return state = false;
        default:
            return state;
    }
}



