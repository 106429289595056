import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RobotMovement from "../../js/robotmovement";
import Panels from "../../js/panels";
import { toggleSidebar } from "../../actions/index.js";
import './ControllerView.css';
import VideoPanel from './VideoPanel/VideoPanel';
import TouchControls from './TouchControls/TouchControls';
import Sidebar from './Sidebar/Sidebar'

const ControllerView = () => {
    const panelView = useSelector(state => state.panelView);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                Panels.handleEscPress();
            }
            RobotMovement.moveRobotKeyboard(e);
        }
        const handleKeyUp = (e) => {
            RobotMovement.stopRobotKeyboard(e);
        }
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            Panels.endFramerateFetching();
        }
    }, []);

    
    let containerClass;
    let videoPanels = [];

    for (let i = 0; i < panelView; i++) {
        videoPanels.push(<VideoPanel key={i} id={i} />);
    }

    Panels.closePanelHandler(panelView);
    Panels.startFramerateFetching();

    if (panelView === 1) {
        containerClass = ""
    } else if (panelView === 2) {
        containerClass = "grid-2"
    } else if (panelView === 1.99) {
        containerClass = "grid-2-horizontal"
    } else if (panelView === 4) {
        containerClass = "grid-4"
    }

    return (
        <div id="controllerView" className="contoller-container">
            <div className="controller-content">
                < Sidebar />
                <div id="VideoPanels" className={"video-container " + containerClass}>
                    < SidebarToggleIcons/>
                    < TouchControls/>
                    < FullscreenControl/>
                    {videoPanels}
                </div>
            </div>
        </div>
    );
}

const SidebarToggleIcons = () => {
    const showSidebarView = useSelector(state => state.showSideBar);
    const dispatch = useDispatch();

    return(
        <div className="sidebar-toggle-icon-container" onClick={() => dispatch(toggleSidebar())}>
            <i className="material-icons">{showSidebarView? 'arrow_left' : 'arrow_right'}</i>
        </div>
    )
}

const FullscreenControl = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const showTouchControls = useSelector(state => state.showTouchControls);

    useEffect(() => {
        const fullscreenSubscription = Panels.fullscreenObservable.subscribe(newIsFullscreen => {
            setIsFullscreen(newIsFullscreen);
        });

        return () => {
            fullscreenSubscription.unsubscribe();
        } 
    });

    const requestFullscreen = () => {
        const VideoPanels = document.getElementById("controllerView");
        Panels.openFullscreen(VideoPanels);
    }

    const stopFullscreen = () => {
        Panels.closeFullscreen();
    }

    return (
        !showTouchControls?(
            !isFullscreen?(
                <div className="fullscreen-icon-container" onClick={() => requestFullscreen()}>
                    <i className="material-icons">fullscreen</i>
                </div>
            )
            : (
                <div className="fullscreen-icon-container" onClick={() => stopFullscreen()}>
                    <i className="material-icons">fullscreen_exit</i>
                </div>
            )
        ) : null
    )
}

export default ControllerView;