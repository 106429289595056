import { toggleAdminView } from '../../actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { setAdminViewContext } from "../../actions/index";
import { setActiveCompany } from '../../actions';
import Requests from '../../js/requests';

const ToggleAdminViewBtn = () => {
    const permissionLevel = useSelector(state => state.permissionLevel);
    const dispatch = useDispatch();

    const handleAdminBtnClick = async () => {
        
        if ((permissionLevel < 2)) {
            await Requests.getCompany().then(res => {
                console.log(res);
                dispatch(setActiveCompany(res.data));
                dispatch(setAdminViewContext("PERMISSION_VIEW"));
            });
        }
        dispatch(toggleAdminView());
    }

    return (
        <>
        {(permissionLevel > 0) ? 
            <button className="btn btn-primary" onClick={() => handleAdminBtnClick()}>Admin View</button>
        : null}
        </>
    )
}

export default ToggleAdminViewBtn;