import { Subject } from "rxjs";
import store from "../index.js";
import { connect, disconnect, gatheringIce, gatheringIceComplete, initialState, openPayloadDialog, closePayloadDialog } from "../actions/index.js";
import { timeout } from "./utils.js";
import RobotMovement from "./robotmovement.js";
import Notifications from "./notifications";
import Panels from "./panels.js";
import RobotModel from "./robotmodel";
import { localStorageAddItem } from "./utils";

class RobotConnection {
    static pc; // PeerConnection
    static ws; // Websocket
    static dc; // DataChannel
    static statusdc; //StatusDataChannel
    static chatdc; //ChatDataChannel
    static userAudioStream;
    static roomId;
    static clientId;
    static serial = "";
    static isInitiator;
    static sdpSent = false;
    static robotInformationObj = {};
    static candidateQueue = [];
    static audioElement;
    static robotAudioStream;

    static robotDataObservable = new Subject();
    static videoDataObservable = new Subject();

    static robotData = {
        "connectionState" : "",
        "powerState" : false,
        "powerStateString": "",
        "battery" : "",
        "batteryLifeTime": "",
        "robotStanding" : false
    }

    static videoData = {
        "robotMuted": false,
        "userMuted": false
    }

    static flag;

    static async startConnection() {
        const configuration = {
            'iceServers': [
                {'urls': 'turn:spot.trimble.com:3478', username: "AzureUser", credential: "p@ssw0rd"},
                {'urls':'stun:turn2.l.google.com'},
                {'urls':'stun:stun.l.google.com:19302'},
                // {'urls':'stun:stun1.l.google.com:19302'},
                // {'urls':'stun:stun2.l.google.com:19302'},
                // {'urls':'stun:stun3.l.google.com:19302'},
                // {'urls':'stun:stun4.l.google.com:19302'},
            ]
        }
        this.pc = new RTCPeerConnection(configuration);
        await this.setUserMedia();
        this.setListeners();
        this.ws = new WebSocket('wss://spot.trimble.com:8765');
        this.ws.onopen = async (event) => {
            this.sendGetRoom();
        }
    }

    static sendGetRoom = () => {
        const params = '{"cmd": "get_room", "serial": "' + this.serial + '"}'
        this.ws.send(params);
        this.ws.onmessage = async (message) => {
            console.log(message);
            const data = JSON.parse(message.data);
            if (data.cmd === "error") {
                Notifications.error(data.msg);
                this.closeConnection();
                console.log(data);
            }
            else if (data.cmd === "in_session") {
                Notifications.warn(data.msg);
                this.closeConnection();
            }
            else {
                this.roomId = data.roomid;
                this.clientId = data.clientid;
                this.isInitiator = data.is_initiator;
                this.register();
            }
        }
    }


    static register() {
        console.log("Registering");
        this.ws = new WebSocket('wss://spot.trimble.com:8765');
        const params = '{"cmd": "register", "clientid": "' + this.clientId +'", "roomid": "' + this.roomId +'"}';
        this.ws.onopen = () => {
            this.ws.send(params);
            this.authorize();
        }
        this.ws.onmessage = async (message) => {
            console.log("Message from server:");
            console.log(message);
            const data = JSON.parse(message.data);
            const msg = JSON.parse(data.msg);
            // console.log(msg);
            if (msg.cmd === "wait") {
                Notifications.info(msg.msg);
            }
            if (msg.sdp) {
                const remoteDesc = new RTCSessionDescription(msg);
                this.pc.setRemoteDescription(remoteDesc).then(() => {
                    console.log("Remote description set");
                    this.addQueuedIceCandidates();
                });
                if (this.isInitiator !== "true") {
                    this.createAnswer();
                }
            }
            if (msg.candidate) {
                try {
                    msg.sdpMid = msg.id;
                    msg.sdpMLineIndex = msg.label;
                    const candidate = new RTCIceCandidate({
                        sdpMLineIndex: msg.label,
                        candidate: msg.candidate
                    });
                    console.log(candidate);
                    if (!this.pc || !this.pc.currentRemoteDescription){
                        this.candidateQueue.push(candidate);
                    } else {
                        this.pc.addIceCandidate(candidate)
                        .then(() => {
                            console.log("Ice candidate added succesfully")
                        })
                        .catch(() => console.log("Error adding received ice candidate"));
                    }
                    
                } catch (e) {
                    console.error('Error adding received ice candidate', e);
                }
            }
        }
    }

    static authorize() {
        const params = '{"cmd": "auth", "serial": "' + this.serial + '", "roomid": "' + this.roomId + '"}';
        this.ws.send(params);
        if (this.isInitiator !== "false") {
            this.createOffer();
        }
    }

    static async addQueuedIceCandidates() {
        console.log("Adding queued candidates")
        try {
            for (const candidate in this.candidateQueue) {
                if (candidate.type === 'RTCIceCandidate') {
                    this.pc.addIceCandidate(candidate)
                    .then(() => {
                        console.log("Ice candidate added succesfully from queue")
                    })
                    .catch(() => console.log("Error adding queued ice candidate"));
                }
            }
            this.candidateQueue = [];
        } catch (e) {
            console.error('Error adding queued ice candidate', e);
        }
    }
    
    static createOffer() {
        this.pc.createOffer().then(offer => {
            this.pc.setLocalDescription(offer).then(() => {
                
            });
        }).then((e) => {
            console.log("Offer set")
        });
    }
    
    static createAnswer() {
        this.pc.createAnswer().then(answer => {
            this.pc.setLocalDescription(answer).then(() => {
            });
        }).then((e) => {
            console.log("Answer set")
        });
    }

    static setListeners() {
        // Peer Connection listeners
        this.pc.addEventListener('icecandidate', event => {
            if (event.candidate) {
                console.log(event.candidate);
                const message = {
                    'candidate': event.candidate.candidate,
                    'id': event.candidate.sdpMid,
                    'label': event.candidate.sdpMLineIndex,
                    'type': 'candidate'
                };
                const params = {
                    "cmd": "send",
                    "msg": JSON.stringify(message)
                };
                this.ws.send(JSON.stringify(params));
            };
        });

        this.pc.onicegatheringstatechange = (e) => {
            let connection = e.target;
          
            switch(connection.iceGatheringState) {
                case "gathering":
                    console.log("Ice Gathering State Changed: gathering");
                    store.dispatch(gatheringIce());
                    break;
                case "complete":
                    console.log("Ice Gathering State Changed: complete");
                    const params = {"cmd": "send", "msg": JSON.stringify(this.pc.localDescription)};
                    console.log(JSON.stringify(params));
                    this.ws.send(JSON.stringify(params));
                    break;
                default:
                    return;
            }
        }

        this.pc.onnegotiationneeded = (e) => {
            console.log(e);
        }

        this.pc.addEventListener('connectionstatechange', event => {
            if (this.pc.connectionState === 'connected') {
                console.log("Peers connected!");
                localStorageAddItem("Serial", this.serial);
                store.dispatch(connect());
                store.dispatch(gatheringIceComplete());
            } else if (this.pc.connectionState === 'disconnected') {
                console.log("Peers disconnected!");
                this.delayedClose();
                // store.dispatch(disconnect());
            }
            this.robotData.connectionState = this.pc.connectionState;
            this.robotDataObservable.next(this.robotData);
        });

        this.pc.addEventListener('track', async (event) => {
            console.log(event.track);
            if (event.track.kind === 'audio') {
                this.robotAudioStream = new MediaStream();
                this.robotAudioStream.addTrack(event.track, this.robotAudioStream);

                this.audioElement = new Audio()
                this.audioElement.srcObject = this.robotAudioStream;
                this.audioElement.play();
                this.videoData["robotMuted"] = this.audioElement.muted;
                this.videoDataObservable.next(this.videoData);
            } else {
                switch (event.track.label) {
                    case "frame1":
                        Panels.panelData[0].addTrackToPanel(event.track);
                        break;
                    case "frame1_2":
                        Panels.panelData[0].addSecondTrackToPanel(event.track);
                        break;
                    case "frame2":
                        Panels.panelData[1].addTrackToPanel(event.track);
                        break;
                    case "frame3":
                        Panels.panelData[2].addTrackToPanel(event.track);
                        break;
                    case "frame4":
                        Panels.panelData[3].addTrackToPanel(event.track);
                        break;
                    default:
                        break;
                }
            }
        });

        // Set data channel
        this.dc = this.pc.createDataChannel("datachannel");

        this.dc.onmessage = (e) => {
            console.log(e.data);
            const msg = JSON.parse(e.data);
            if (msg.MsgType === 0) {
                Panels.initializePanelLists(msg.Message);
            }
            if (msg.MsgType === 4) {
                const title = msg.Message.Title;
                const description = msg.Message.Description;
                if (description && title && title !== "") {
                    Notifications.info(description, title);
                } else if (description) {
                    Notifications.info(description);
                } else {
                    Notifications.info(msg.Message);
                }
            }
            if (msg.MsgType === 5) {
                const title = msg.Message.Title;
                const description = msg.Message.Description;
                if (description && title && title !== "") {
                    Notifications.warn(description, title);
                } else if (description) {
                    Notifications.warn(description);
                } else {
                    Notifications.warn(msg.Message);
                }
            }
            if (msg.MsgType === 6) {
                const title = msg.Message.Title;
                const description = msg.Message.Description;
                if (description && title && title !== "") {
                    Notifications.error(description, title);
                } else if (description) {
                    Notifications.error(description);
                } else {
                    Notifications.error(msg.Message);
                }
            }
            if (msg.MsgType === 7) {
                if (msg.Message.PayloadMsgType === 0) {
                    store.dispatch(openPayloadDialog());
                }
                if (msg.Message.PayloadMsgType === 1) {
                    // payload progress
                }
                if (msg.Message.PayloadMsgType === 2) {
                    store.dispatch(closePayloadDialog());
                }
            }
        };
        this.dc.onopen = () => {
            console.log("Data channel open");
        };

        // Set status data channel
        this.statusdc = this.pc.createDataChannel("statusdatachannel");

        this.statusdc.onmessage = (e) => {
            const robotData = JSON.parse(e.data);
            if (!this.flag) {
                console.log(robotData);
                this.flag = true;
            }
            
            this.handleRobotData(robotData);            
        };

        this.statusdc.onopen = () => {
            console.log("Status channel open");
        };
    }

    static async closeConnection() {
        if (this.ws) {
            const params = {"cmd": "send", "msg": JSON.stringify({"type" : "bye"})};
            await this.ws.send(JSON.stringify(params));
            this.ws.close();
        }
        if (this.pc) {
            this.pc.close();
        }
        if (this.userAudioStream) {
            for (const track of this.userAudioStream.getAudioTracks()) {
                track.stop();
            }
        }
        this.videoData["robotMuted"] = false;
        this.videoData["userMuted"] = false;
        Panels.resetOnClose();
        RobotMovement.resetOnClose();
        store.dispatch(initialState());  
        store.dispatch(gatheringIceComplete());
        store.dispatch(disconnect());
    }

    static async delayedClose() {
        await timeout(10000);
        if (this.pc.connectionState === 'disconnected' || this.pc.connectionState === 'failed') {
            console.log("Connection has timed out");
            Notifications.error("The remote client unexpectedly disconnected");
            this.closeConnection();
        }
    }

    static handleRobotData(data) {
        // console.log(data);
        
        const kinematicState = data.kinematicState;
        if (kinematicState.jointStates) {
            RobotModel.updateJoints(kinematicState.jointStates);
        }

        const powerState = data.powerState;
        this.robotData["powerState"] = powerState.motorPowerState === 'STATE_ON';
        this.robotData["powerStateString"] = powerState.motorPowerState;
        this.robotData["battery"] = powerState.locomotionChargePercentage;
        this.robotData["batteryLifeTime"] = powerState.locomotionEstimatedRuntime;
        this.robotDataObservable.next(this.robotData);
    };

    static sendCommand(command) {
        if (this.dc.readyState === "open") {
            console.log("Sending command: " + command);
            this.dc.send(command);
        } else {
            console.log("Trying to send command while dc is not open");
        }
    };

    static setUserMedia() {
        try {
            navigator.mediaDevices.getUserMedia({video: false, audio: true}).then(stream => {
                this.userAudioStream = stream;
                for (const track of this.userAudioStream.getAudioTracks()) {
                    this.pc.addTrack(track);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    static getRobotData() {
        return this.robotData;
    }

    static getRobotMuted() {
        return this.videoData["robotMuted"];
    }

    static getUserMuted() {
        return this.videoData["userMuted"];
    }

    static setRobotMuted() {
        if (this.audioElement) {
            this.audioElement.muted? this.audioElement.muted = false : this.audioElement.muted = true;
            this.videoData["robotMuted"] = this.audioElement.muted;
            this.videoDataObservable.next(this.videoData);
        }  
    };

    static setUserMuted() {
        const muted = !this.userAudioStream.getAudioTracks()[0].enabled
        if (muted) {
            this.userAudioStream.getAudioTracks()[0].enabled = true;
            this.videoData["userMuted"] = false;
        } else {
            this.userAudioStream.getAudioTracks()[0].enabled = false;
            this.videoData["userMuted"] = true;
        }
        this.videoDataObservable.next(this.videoData);
    };

    static sendSit() {
        this.sendCommand('v');
        if (this.robotData["powerState"]) {
            this.robotData["robotStanding"] = false;
            this.robotDataObservable.next(this.robotData);
            RobotMovement.addToUsedCommands('v');
        }
    }

    static sendStand() {
        this.sendCommand('f');
        if (this.robotData["powerState"]) {
            this.robotData["robotStanding"] = true;
            this.robotDataObservable.next(this.robotData);
            RobotMovement.addToUsedCommands('f');
        }
    }
};

export default RobotConnection;
