import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Nav from './Views/NavigationView/Nav.js';
import Content from './Content.js';
import PayloadDialog from './Views/DialogViews/PayloadDialog'
import RobotConnection from "./js/robotconnection.js";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';


function App() {
  const showApp = useSelector(state => state.loggedIn);

  useEffect(() => {
    window.onbeforeunload = (e) => {
      RobotConnection.closeConnection();
    };
  });

  return (
    <div className="App">
      { showApp?
        [
          < Nav key={0}/>, 
          < Content key={1}/>
        ]
      : ''
      }
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      < PayloadDialog/>
    </div>
  );
}

export default App;
