export const connectionReducer = (state = false, action) => {
    switch (action.type) {
        case "CONNECTED":
            return state = true;
        case "DISCONNECTED":
            return state = false;
        default:
            return state;
    }
}

export const gatheringIceReducer = (state = false, action) => {
    switch (action.type) {
        case "COMPLETE":
            return state = false;
        case "GATHERING":
            return state = true;
        default:
            return state;
    }
}