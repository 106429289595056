import { useSelector, useDispatch } from 'react-redux';
import { setAdminViewContext, toggleAdminView } from "../../actions/index";
import { setActiveCompany } from '../../actions';
import PermissionView from './PermissionView';

import './AdminView.css';
import CompanyView from './CompanyView';

const AdminView = () => {
    const adminViewContext = useSelector(state => state.adminContext);
    const permissionLevel = useSelector(state => state.permissionLevel);
    const dispatch = useDispatch();

    const handleCloseAdminClick = () => {
        dispatch(toggleAdminView());
        dispatch(setAdminViewContext("ADMIN_VIEW"));
    }

    const getAdminContext = () => {
        if (adminViewContext === "ADMIN_VIEW" && (permissionLevel > 1)) {
            return ( <CompanyView /> )
        }
        if (adminViewContext === "PERMISSION_VIEW" || (permissionLevel < 2)) {
            return ( <PermissionView /> )
        }
        return null;
    }
    return (
        <div className="admin-container">
            <div className="admin-content">
                <div className="admin-header">
                    <h1>Admin Panel</h1>
                    <button type="button" className="close admin-close" onClick={() => handleCloseAdminClick()}>
                        <span>×</span>
                    </button>
                </div>
                {getAdminContext()}
            </div>
        </div>
    )
}

export default AdminView;