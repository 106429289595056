import authReducer from "./auth.js";
import { connectionReducer, gatheringIceReducer } from "./connection.js";
import { logReducer, sidebarReducer, panelViewReducer, frozenPanelReducer,
    touchControlsReducer, notificationLogReducer, payloadDialogReducer } from "./ui.js";
import { adminViewReducer, adminViewContextReducer, permissionLevelReducer} from "./admin.js";
import { companyReducer } from "./company.js";
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    loggedIn: authReducer,
    connected: connectionReducer,
    showSideBar: sidebarReducer,
    showLog: logReducer,
    gatheringIceCandidate: gatheringIceReducer,
    panelView: panelViewReducer,
    frozenPanels: frozenPanelReducer,
    showTouchControls: touchControlsReducer,
    showNotificationLog: notificationLogReducer,
    showPayloadDialog: payloadDialogReducer,
    showAdminView: adminViewReducer,
    adminContext: adminViewContextReducer,
    selectedCompany: companyReducer,
    permissionLevel: permissionLevelReducer
});

export default allReducers;