import { Subject } from "rxjs";
import RobotConnection from "./robotconnection.js";
import { currentTimeString } from "./utils.js";
import nipplejs from 'nipplejs';

class RobotMovement {
    static currentCommand;
    static timer;
    static usedCommands = [];
    static joystickRight;
    static joystickLeft;
    static joystickRightOption = "ROTATE_ROBOT";
    static joystickLeftOption = "MOVE_ROBOT";

    static commandObservable = new Subject();
    static joysticksDropupObservable = new Subject();

    static commands = {
        "Digit0": "0",
        "Digit1": "1",
        "Digit2": "2",
        "Digit3": "3",
        "Digit4": "4",
        "Digit5": "5",
        "KeyW": "w",
        "KeyS": "s",
        "KeyA": "a",
        "KeyD": "d",
        "KeyQ": "q",
        "KeyE": "e",
        "KeyP": "P",
        "KeyV": "v",
        "KeyF": "f",
        "KeyX": "x",
        "KeyI": "i",
        "KeyJ": "j",
        "KeyK": "k",
        "KeyL": "l",
        "KeyU": "u",
        "KeyO": "o",
        "Space": " ",
        "Period": ".",
        "KeyM": "m",
        "KeyZ": "z",
        "KeyC": "c",
        "KeyY": "y",
    }
    
    static commandDesc = {
        "0": "Operate payload",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "w": "Forward",
        "s": "Backward",
        "a": "Strafe left",
        "d": "Strafe right",
        "q": "Turn left",
        "e": "Turn right",
        "P": "Power on",
        "v": "Sit",
        "f": "Stand",
        "x": "Stop robot",
        "i": "Tilt up",
        "j": "Pan left",
        "k": "Tilt down",
        "l": "Pan right",
        "u": "Zoom in",
        "o": "Zoom out",
        " ": "Stop panning",
        ".": "Stop zooming",
        "m": "PTZ home",
        "z": "",
        "c": "",
        "y": "Docking robot"
    }

    static ptzMoveCommands = ["KeyI", "KeyJ", "KeyK", "KeyL"];
    static ptzZoomCommands = ["KeyU", "KeyO"];
    static loopCommands = ["KeyW", "KeyS", "KeyA", "KeyD", "KeyQ", "KeyE"]
    static joystickOptions = ["MOVE_ROBOT", "ROTATE_ROBOT"];

    static joystickControlls = {
        "MOVE_ROBOT": {
            "Up": "KeyW",
            "Right": "KeyD",
            "Down": "KeyS",
            "Left": "KeyA"
        },
        "ROTATE_ROBOT": {
            "Up": "",
            "Right": "KeyE",
            "Down": "",
            "Left": "KeyQ"
        },
        "ROTATE_PTZ": {
            "Up": "KeyI",
            "Right": "KeyL",
            "Down": "KeyK",
            "Left": "KeyJ"
        },
        "ZOOM_PTZ": {
            "Up": "KeyU",
            "Right": "",
            "Down": "KeyO",
            "Left": ""
        }
    }

    static resetOnClose() {
        this.usedCommands = [];
    }

    static moveRobotKeyboard(e) {
        this.moveRobot(e.code, e)
    }

    static moveRobot(key, e = null) {
        // console.log(key)
        if (this.currentCommand !== key || !(key in this.commands)) {
            if (key in this.commands) {
                if ((key !== "KeyP") || (key === "KeyP" && e.shiftKey)) {
                    this.currentCommand = key;
                    this.usedCommands.push([this.commands[key], currentTimeString()]);
                    this.commandObservable.next(this.usedCommands);
                    this.clearTimer();
                    RobotConnection.sendCommand(this.commands[key]);
                } 
                
            } else {
                console.log("Command: " + key + " not found in commands")
            }
    
            if (this.loopCommands.includes(this.currentCommand)) {
                this.moveLoop();
            }
        }
    }

    static addToUsedCommands(command) {
        this.usedCommands.push([command, currentTimeString()]);
        this.commandObservable.next(this.usedCommands);
    }

    static stopRobotKeyboard(e) {
        this.stopMovement(e.code);
    }
    
    static stopMovement(key) {
        if (this.currentCommand === key) {
            this.currentCommand = null;
            this.clearTimer();
        }
        if (this.ptzMoveCommands.includes(key)) {
            RobotConnection.sendCommand(' ');
        }
        if (this.ptzZoomCommands.includes(key)) {
            RobotConnection.sendCommand('.');
        }
    }
    
    static moveLoop() {
        this.timer = setInterval(() => {
            if (this.currentCommand != null) {
                // console.log("sending message: " + this.commands[this.currentCommand]);
                RobotConnection.sendCommand(this.commands[this.currentCommand]);
            } else {
                this.clearTimer();
            }
        }, 100)
    }
    
    static clearTimer() {
        clearInterval(this.timer);
        this.timer = null;
    }

    static getCommands() {
        return this.usedCommands;
    }

    static addJoystickRightToElement(element) {
        this.joystickRight = nipplejs.create({
            zone: element,
            mode: 'static',
            position: {left: '50%', top: '50%'},
            size: 120,
            color: '#F3F3F7',
            threshold: 0.3
        });

        this.joystickRight.on('dir:up', (e, data) => {
            // console.log(e);
            // console.log(data);
            const command = this.joystickControlls[this.joystickRightOption]["Up"]
            if (command !== "") {
                this.moveRobot(command);
            }
        });
        this.joystickRight.on('dir:right', (e, data) => {
            const command = this.joystickControlls[this.joystickRightOption]["Right"];
            if (command !== "") {
                this.moveRobot(command);
            }
        });
        this.joystickRight.on('dir:down', (e, data) => {
            const command = this.joystickControlls[this.joystickRightOption]["Down"];
            if (command !== "") {
                this.moveRobot(command);
            }
        });
        this.joystickRight.on('dir:left', (e, data) => {
            const command = this.joystickControlls[this.joystickRightOption]["Left"];
            if (command !== "") {
                this.moveRobot(command);
            }
        });
        this.joystickRight.on('move', (e, data) => {
            if (data.force < 0.2 && this.currentCommand) {
                this.stopMovement(this.currentCommand);
            }
        });
        this.joystickRight.on('end', (e, data) => {
            this.stopMovement(this.currentCommand);
        });
    }

    static addJoystickLeftToElement(element) {
        this.joystickLeft = nipplejs.create({
            zone: element,
            mode: 'static',
            position: {left: '50%', top: '50%'},
            size: 120,
            color: '#F3F3F7',
            threshold: 0.3
        });

        this.joystickLeft.on('dir:up', (e, data) => {
            // console.log(e);
            // console.log(data);
            const command = this.joystickControlls[this.joystickLeftOption]["Up"]
            if (command !== "") {
                this.moveRobot(command);
            }
        });
        this.joystickLeft.on('dir:right', (e, data) => {
            const command = this.joystickControlls[this.joystickLeftOption]["Right"];
            if (command !== "") {
                this.moveRobot(command);
            }
        });
        this.joystickLeft.on('dir:down', (e, data) => {
            const command = this.joystickControlls[this.joystickLeftOption]["Down"];
            if (command !== "") {
                this.moveRobot(command);
            }
        });
        this.joystickLeft.on('dir:left', (e, data) => {
            const command = this.joystickControlls[this.joystickLeftOption]["Left"];
            if (command !== "") {
                this.moveRobot(command);
            }
        });
        this.joystickLeft.on('move', (e, data) => {
            if (data.force < 0.2 && this.currentCommand) {
                this.stopMovement(this.currentCommand);
            }
        });
        this.joystickLeft.on('end', (e, data) => {
            this.stopMovement(this.currentCommand);
        });
    }

    static destroyJoystickRight() {
        if (this.joystickRight) {
            this.joystickRight.destroy();
        }
    }

    static destroyJoystickLeft() {
        if (this.joystickLeft) {
            this.joystickLeft.destroy();
        }
    }

    static addPtzControls() {
        this.joystickOptions = this.joystickOptions.concat(["ROTATE_PTZ", "ZOOM_PTZ"]);
    }

    static getJoystickOptions() {
        return this.joystickOptions;
    }

    static setJoystickRightOption(option) {
        this.joystickRightOption = option;
        this.joysticksDropupObservable.next([this.joystickLeftOption, this.joystickRightOption]);
    }

    static setJoystickLeftOption(option) {
        this.joystickLeftOption = option;
        this.joysticksDropupObservable.next([this.joystickLeftOption, this.joystickRightOption]);
    }
}

export default RobotMovement;