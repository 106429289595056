import React from 'react';
import logo from '../../assets/trimble_logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RobotConnection from "../../js/robotconnection.js";
import { logout } from '../../auth/authentication';
import { logoutAction, toggleNotificationLog } from "../../actions/index.js";
import ToggleAdminViewBtn from '../../Components/Buttons/ToggleAdminViewBtn';
// import Notifications from "../../js/notifications";
import './Nav.css';

function Nav() {
	const { t } = useTranslation();
	const showControllerView = useSelector(state => state.connected);
	const showNotificationLog = useSelector(state => state.showNotificationLog);
	const dispatch = useDispatch();

	const handleLogout = () => {
		logout();
		dispatch(logoutAction());
	}

// const notify = () => {
//     Notifications.info("Testing info message Testing info message Testing info message");
//     setTimeout(() => {
//     	Notifications.success("Testing success message Testing success message");
//     }, 1000)
//     setTimeout(() => {
//     	Notifications.warn("Testing warn message");
//     }, 2000)
//     setTimeout(() => {
//     	Notifications.error("Testing error message");
//     }, 3000)
// }

	const logToggle = () => {
			dispatch(toggleNotificationLog());
	}

	return (
		<nav className="nav navbar navbar-expand-lg modus-header spot-header">
			<div className="navbar-nav mr-auto spot-navbar-nav">
				<div className="logo-container">
					<img className="logo" alt="" src={logo}></img>
				</div>
				<div className="navbar-text flex-block">
					<span className="h3">SPOT Web</span>
				</div>
				<div className="nav-right-controls">
					{/* <button className="btn btn-primary" onClick={() => notify()}>Test</button> */}
					<div className="large-screen">
						<button className={"btn btn-icon-only btn-text-primary nav-icon-button mr-2 " + (showNotificationLog? 'active' : '')} onClick={() => logToggle()}>
							<i className="modus-icon material-icons nav-icon">circle_notifications</i>
						</button>
						< ToggleAdminViewBtn/>
						{showControllerView?
						<button className="btn btn-primary ml-2" onClick={() => RobotConnection.closeConnection()}>{t("DISCONNECT")}</button>
						:
						<button className="btn btn-primary ml-2" onClick={() => handleLogout()}>{t("LOGOUT")}</button>}
					</div>
					<div className="small-screen">
						<div className="dropdown">
							<button className="btn btn-icon-only btn-text-primary d-flex justify-content-center align-items-center" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="material-icons">menu</i>
							</button>
							<div className="dropdown-menu dropdown-menu-right p-0">
								<li className="list-group-item list-item-right-control" onClick={() => logToggle()}>
									{t("NOTIFICATIONS")}
									<i className="material-icons">circle_notifications</i>
								</li>
								{showControllerView? <li className="list-group-item list-item-right-control" onClick={() => RobotConnection.closeConnection()}>
									{t("DISCONNECT")}
									<i className="material-icons"></i>
								</li> : null}
								{!showControllerView? <li className="list-group-item list-item-right-control" onClick={() => handleLogout()}>
									{t("LOGOUT")}
									<i className="material-icons">logout</i>
								</li> : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Nav;
