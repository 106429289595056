export const permissionLevelReducer = (state = 0, action) => {
    switch (action.type) {
        case "SET_PERMISSION_LEVEL":
            return state = action.permissionLevel;
        default:
            return state;
    }
}

export const adminViewReducer = (state = false, action) => {
    switch (action.type) {
        case "TOGGLE_ADMIN_VIEW":
            return state = !state;
        default:
            return state;
    }
}

export const adminViewContextReducer = (state = "ADMIN_VIEW", action) => {
    switch (action.type) {
        case "SET_ADMIN_VIEW_CONTEXT":
            return state = action.view;
        default:
            return state;
    }
}