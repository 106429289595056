import React, { useEffect, useState} from 'react';
import './Dialog.css'

const endpoint = "http://localhost:5001";

const AddRobot = (props) => {
    
    const [state, setState] = useState({
        name: "",
        companyId: "",
        serial: ""
    });

    const changeHandler = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });
    }

    const close = () => {
        props.close();
    }

    const AddRobot = () => {
        props.add(state);
    };

    return (
        <>
        <div className="backdrop"></div>
        <div className="modal fade show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modal-title-1">
                            Add robot</h5>
                        <button type="button" className="close" onClick={() => close()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label htmlFor="locationName">Robot name</label>
                        <input 
                            className="form-control"
                            id="locationName"
                            placeholder="Robot name"
                            type="text"
                            name="name"
                            value={state.name}
                            onChange={(e) => changeHandler(e)}
                        />
                        <label htmlFor="robotId">Robot serial</label>
                        <input 
                            className="form-control"
                            id="robotId"
                            placeholder="Robot serial"
                            type="text"
                            name="serial"
                            value={state.serial}
                            onChange={(e) => changeHandler(e)}
                        />
                    </div>
                </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" onClick={() => close()}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => AddRobot()}>
                            Add robot
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default AddRobot;