import React, { useState } from 'react';
import './Dialog.css'

const AddCompany = (props) => {
    
    const [companyName, setCompanyName] = useState('');

    const changeHandler = (e) => {
        setCompanyName(e.target.value)
    }

    const close = () => {
        props.close();
    }

    const addCompany = () => {
        props.add(companyName);
    };

    return (
        <>
        <div className="backdrop"></div>
        <div className="modal fade show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modal-title-1">
                            Add company</h5>
                        <button type="button" className="close" onClick={() => close()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label htmlFor="companyName">Company name</label>
                        <input className="form-control" id="companyName" placeholder="Company name" type="text"
                        name="companyName"
                        value={companyName}
                        onChange={(e) => changeHandler(e)} />
                    </div>
                </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" onClick={() => close()}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => addCompany()}>
                            Add company
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
};

export default AddCompany;
