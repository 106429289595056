import SelectionView from '../SelectionView/SelectionView';
import AdminView from '../AdminView/AdminView';
import selectionViewBackground from '../../assets/spot_trimble.png';
import { useSelector } from 'react-redux';
import './StartView.css';

const StartView = () => {
    const showAdminView = useSelector(state => state.showAdminView);

    return (
        <div id="selectionView" className="selection-continer">
            <img src={selectionViewBackground} alt="" className="selection-image"></img>
                {!showAdminView ?
                    <SelectionView />
                :
                    <AdminView />
                }
            
        </div>
    )
}

export default StartView;