import {
    WebGLRenderer,
    PerspectiveCamera,
    Scene,
    PCFSoftShadowMap,
    sRGBEncoding,
    Color,
    Vector3,
} from 'three';
import * as THREE from 'three';
import store from "../index.js";
import Panels from "./panels";
import { vshader, fshader} from './shaders';
import RobotConnection from './robotconnection';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

const rightFrameMVP = new THREE.Matrix4().set(
    -0.18646688,  0.28302087, -0.53935054, -0.72422304,  // row 0
    -0.65247076, -0.12495509, -0.29000926, -2.7131789,   // row 1
    -0.71113867,  0.60811335, -0.35281715, -3.20011536,  // row 2
    0,           0,           0,           1,           // row 3
);

const leftFrameMVP = new THREE.Matrix4().set(
    -0.25019587, -0.2371575,  -0.54109022, -0.80882769,  // row 0
    -0.21952725, -0.69920235, -0.05422367, -0.65182874,  // row 1
    -0.83368333, -0.42828692, -0.34862934, -3.34962306,  // row 2
    0,           0,           0,           1,           // row 3
);

class RobotFrontCamera {
    static scene;
    static camera;
    static renderer;
    static robot;
    static panel;
    static video;
    static raycaster;
    static mouse;
    static mouseIndicator;
    static onResizeSubscription;

    // Shaders
    static plane;
    static texture;

    static flag = true;

    static init = (ref, id) => {
        this.panel = ref;
        this.scene = new Scene();
        this.scene.background = new Color(0x000000);
        this.camera = new PerspectiveCamera(110, this.panel.offsetWidth / this.panel.offsetHeight, 0.01, 50);

        this.camera.up = new THREE.Vector3(0, 0, 1);
        this.camera.position.set(-4.761671174712531, 0.4245615557021436, 0.305597731260389);
        this.camera.lookAt(new THREE.Vector3(-6.5693597712934615, 0.661378814846042, -0.5167036268576917));
        this.camera.updateProjectionMatrix();

        // -4.761671174712531 0.4245615557021436 0.305597731260389 -6.5693597712934615 0.661378814846042 -0.5167036268576917 -0.0011067411936692942 -0.0014354152303396376 0.9999983573521742

        this.renderer = new WebGLRenderer({ antialias: true });
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = PCFSoftShadowMap;

        this.panel.appendChild(this.renderer.domElement);

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        // this.controls.minDistance = 4;
        // this.controls.target.y = 1;
        // this.controls.update();

        this.video = document.createElement('video');
        this.video.srcObject = Panels.panelData[id].media;
        this.video.play();

        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();

        this.onResize();
        window.addEventListener('resize', this.onResize);
        window.addEventListener('mousemove', this.onMouseMove, false);
        this.panel.addEventListener('click', this.onMouseClick, false);
        this.onResizeSubscription = store.subscribe(() => {
            if (!Panels.isStreamOpen("spot_front")) {
                this.reset();
            };
            setTimeout(() => {
                this.onResize();
            });
        });

        this.render();
    }

    static reset = () => {
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.robot = null;
        this.panel.removeEventListener('click', this.onMouseClick);
        this.panel = null;
        this.flag = true;
        if (this.onResizeSubscription) this.onResizeSubscription();
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('mousemove', this.onMouseMove);
        //window.removeEventListener('click', this.onMouseClick);
    }

    static onResize = () => {
        if (this.renderer) {
            this.renderer.setSize(this.panel.offsetWidth, this.panel.offsetHeight);
            this.renderer.setPixelRatio(this.panel.devicePixelRatio);

            this.camera.aspect = this.panel.offsetWidth / this.panel.offsetHeight;
            this.camera.updateProjectionMatrix();
        }
    }

    static getFrames = () => {
        if (this.videoReady()) {

            const videoTexture = new THREE.VideoTexture(this.video);
            videoTexture.wrapS = THREE.RepeatWrapping;
            videoTexture.wrapT = THREE.RepeatWrapping;

            const uniforms = {
                image1: { value: videoTexture },
                camera1_MVP: {value: leftFrameMVP },
                image2: { value: videoTexture },
                camera2_MVP: {value: rightFrameMVP }
            }
            const material = new THREE.ShaderMaterial( {
                uniforms: uniforms,
                vertexShader: vshader,
                fragmentShader: fshader,
                side: THREE.DoubleSide
            });

            const vertices = new Float32Array( [
                -4.62495832, -6.65316165, -6.89767541,
                -10.33230017,  -5.90546812, 5.86426815,
                -8.51376123,  7.97591928,  5.86426815,

                -4.62495832, -6.65316165, -6.89767541,
                -8.51376123,  7.97591928,  5.86426815,
                -2.80641937,  7.22822575, -6.89767541
            ] );

            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
            const screen = new THREE.Mesh(geometry, material);
            screen.frustumCulled = false;
            this.scene.add(screen);

            // Plane for future click movement
            const planeGeometry = new THREE.PlaneGeometry( 20, 40, 32 );
            const planeMaterial = new THREE.MeshBasicMaterial( {
                opacity: 0,
                transparent: true,
                // color: 0x263238,
                // side: THREE.DoubleSide
            });
            const plane = new THREE.Mesh( planeGeometry, planeMaterial );
            plane.position.set(-10, 0, -3);
            plane.name = "terrain";
            this.scene.add( plane );

            // Blue sphere to show mouse movement on the plane
            const ringGeometry = new THREE.RingGeometry( 0.15, 0.3, 32 );
            const ringMaterial = new THREE.MeshBasicMaterial({color: 0xFFA500});
            this.mouseIndicator = new THREE.Mesh( ringGeometry, ringMaterial );
            const edges = new THREE.EdgesGeometry( ringGeometry );
            const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x000000 } ) );
            line.renderOrder = 999;
            line.material.depthTest = false;
            line.material.depthWrite = false;
            line.onBeforeRender = function (renderer) { renderer.clearDepth(); };
            this.mouseIndicator.add( line );
            this.mouseIndicator.renderOrder = 999;
            this.mouseIndicator.material.depthTest = false;
            this.mouseIndicator.material.depthWrite = false;
            this.mouseIndicator.onBeforeRender = function (renderer) { renderer.clearDepth(); };
            this.scene.add( this.mouseIndicator );

            // USED IN DEBUGGING!!! Line for debugging camera direction and position 
            // const lineMaterial = new THREE.LineBasicMaterial({
            //     color: 0x0000ff
            // });
            
            // const direction = new THREE.Vector3( -6.5693597712934615, 0.661378814846042, -0.5167036268576917);
            // direction.normalize();

            // const pointA = new THREE.Vector3( -4.761671174712531, 0.4245615557021436, 0.305597731260389);
            // const pointB = new THREE.Vector3();

            // const distance = 100;
            // // pointA.addVectors( pointA, direction.multiplyScalar( distance ) );
            // pointB.addVectors( pointA, direction.multiplyScalar( -distance ) );
            // pointA.addVectors( pointA, direction.multiplyScalar( -distance ) );
            
            // const points = [];
            // points.push( pointA );
            // points.push( pointB );
            
            // const lineGeometry = new THREE.BufferGeometry().setFromPoints( points );
            // const line = new THREE.Line( lineGeometry, lineMaterial );
            // this.scene.add( line );

            // USED IN DEBUGGING!!! Displaying camera positon with a red spere
            // const camGeometry = new THREE.SphereGeometry( 0.2, 64, 64 );
            // const camMaterial = new THREE.MeshBasicMaterial( {color: 0xff0000} );
            // const camSphere = new THREE.Mesh( camGeometry, camMaterial );
            // camSphere.position.set(-4.761671174712531, 0.4245615557021436, 0.305597731260389);
            // this.scene.add( camSphere );


            // geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
            // this.plane = new THREE.Mesh( geometry, material );
            
            //this.plane = new THREE.Mesh( geometry, material );
            // this.scene.add( this.plane );
            this.flag = false;
            
        }
    }

    static videoReady = () => {
        return this.video.videoWidth > 0 && this.video.videoHeight > 0;
    }

    // FIX THIS: doesn't work in different panel views
    static onMouseMove = (event) => {

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        // this.mouse.x = ( (event.clientX - (window.innerWidth - this.panel.offsetWidth)) / this.panel.offsetWidth ) * 2 - 1;
        // this.mouse.y = - ( (event.clientY - (window.innerHeight - this.panel.offsetHeight)) / this.panel.offsetHeight ) * 2 + 1;
        const rect = this.panel.getBoundingClientRect();
        this.mouse.x = ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        this.mouse.y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
        // console.log("x: " + this.mouse.x, "y: " + this.mouse.y);
        if (this.mouse.x > 1 || this.mouse.x < -1 || this.mouse.y > 1 || this.mouse.y < -1) {
            if (this.mouseIndicator) {
                this.mouseIndicator.visible = false;
            }
            return
        }
        const intersects = this.raycaster.intersectObjects( this.scene.children );
        if (intersects.length > 0) {
            var hitTerrain = false;
            for (let i = 0; i < intersects.length; i++) {
                if (intersects[i].object.name === "terrain") {
                    const pos = intersects[i].point;
                    this.mouseIndicator.position.set(pos.x, pos.y, pos.z);
                    this.mouseIndicator.geometry.attributes.position.needsUpdate = true
                    this.mouseIndicator.visible = true;
                    hitTerrain = true;
                }
            }
            if (!hitTerrain) {
                this.mouseIndicator.visible = false;
            }
        } 
    }

    static onMouseClick = () => {
        const intersects = this.raycaster.intersectObjects( this.scene.children );
        // console.log(intersects);
        if (intersects.length > 0) {
            for ( let i = 0; i < intersects.length; i++ ) {
                if (intersects[i].object.name === "terrain") {
                    const pos = intersects[i].point;

                    const destinationPosition = pos;
                    const cameraUp = this.camera.up;
                    const cameraPosition = this.camera.position;
                    let cameraDirection = new Vector3();
                    this.camera.getWorldDirection(cameraDirection);

                    const msg = {
                        "destinationPosition": {
                            "x": destinationPosition.x,
                            "y": destinationPosition.y,
                            "z": destinationPosition.z,
                        },
                        "cameraUp": {
                            "x": cameraUp.x,
                            "y": cameraUp.y,
                            "z": cameraUp.z,
                        },
                        "cameraPosition": {
                            "x": cameraPosition.x,
                            "y": cameraPosition.y,
                            "z": cameraPosition.z,
                        },
                        "cameraDirection": {
                            "x": cameraDirection.x,
                            "y": cameraDirection.y,
                            "z": cameraDirection.z,
                        }
                    }

                    const fullmsg = {
                        "MsgType": 9,
                        "Message": JSON.stringify(msg)
                    }

                    console.log(JSON.stringify(fullmsg));
                    RobotConnection.dc.send(JSON.stringify(fullmsg));

                    // const geometry = new THREE.SphereGeometry( 0.2, 64, 64 );
                    // const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
                    // const sphere = new THREE.Mesh( geometry, material );
                    // sphere.position.set(pos.x, pos.y, pos.z);
                    // sphere.renderOrder = 998;
                    // sphere.material.depthTest = false;
                    // sphere.material.depthWrite = false;
                    // sphere.onBeforeRender = function (renderer) { renderer.clearDepth(); };

                    // this.scene.add( sphere );
                }
            }
            
        }
    }

    static render = () => {
        if (this.renderer) {
            setTimeout(() => {
                requestAnimationFrame(this.render);
            }, 1000 / 30);
            
            if (this.flag) {
                this.getFrames();
            }
            // Used for clicking movement
            this.raycaster.setFromCamera(this.mouse, this.camera);

            this.renderer.render(this.scene, this.camera);
        }
    }
}

export default RobotFrontCamera;
