import {
    WebGLRenderer,
    PerspectiveCamera,
    Scene,
    Mesh,
    PlaneBufferGeometry,
    ShadowMaterial,
    DirectionalLight,
    PCFSoftShadowMap,
    sRGBEncoding,
    Color,
    AmbientLight,
    // Box3,
    LoadingManager,
    // MathUtils,
} from 'three';
// import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import URDFLoader from 'urdf-loader/src/URDFLoader.js';
import store from "../index.js";
import Panels from "./panels";

class RobotModel {
    static scene;
    static camera;
    static renderer;
    static robot;
    static controls;
    static panel;
    static onResizeSubscription;

    static init = (ref) => {
        this.panel = ref;
        this.scene = new Scene();
        this.scene.background = new Color(0x263238);
    
        this.camera = new PerspectiveCamera();
        this.camera.position.set(2, 2, 2);
        this.camera.lookAt(0, 0, 0);
    
        this.renderer = new WebGLRenderer({ antialias: true });
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = PCFSoftShadowMap;
        this.panel.appendChild(this.renderer.domElement);
    
        const directionalLight = new DirectionalLight(0xffffff, 1.0);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.setScalar(1024);
        directionalLight.position.set(5, 30, 5);
        this.scene.add(directionalLight);
    
        const ambientLight = new AmbientLight(0xffffff, 0.2);
        this.scene.add(ambientLight);
    
        const ground = new Mesh(new PlaneBufferGeometry(), new ShadowMaterial({ opacity: 0.25 }));
        ground.rotation.x = -Math.PI / 2;
        ground.scale.setScalar(30);
        ground.receiveShadow = true;
        this.scene.add(ground);
    
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance = 4;
        this.controls.target.y = 1;
        this.controls.update();
    
        const manager = new LoadingManager();
        let loader = new URDFLoader(manager);
        loader.loadMeshCb = function( path, manager, onComplete ) {
            const objLoader = new OBJLoader( manager );
            objLoader.load(
                path,
                result => {
                    onComplete( result );
                },
                undefined,
                err => {
                    onComplete( null, err );
                }
            );
        };
    
        loader.load('./models/spot.URDF', result => {
            this.robot = result;
        });
    
        // wait until all the geometry has loaded to add the model to the this.scene
        manager.onLoad = () => {
            this.robot.rotation.x = -Math.PI / 2;
            // this.robot.position.y = 1.4;
            this.robot.position.y = 1;
            this.robot.scale.x = 2;
            this.robot.scale.y = 2;
            this.robot.scale.z = 2;
            this.robot.traverse(c => {
                // c.castShadow = true;
            });
    
            this.robot.updateMatrixWorld(true);
            this.scene.add(this.robot);
            
            console.log(this.scene)
        };
    
        this.onResize();
        window.addEventListener('resize', this.onResize);
        this.onResizeSubscription = store.subscribe(() => {
            if (!Panels.isStreamOpen("3d_model")) {
                this.reset();
            };
            setTimeout(() => {
                this.onResize();
            });
        });

        this.render();
    }

    static reset = () => {
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.robot = null;
        this.controls = null;
        this.panel = null;
        if (this.onResizeSubscription) this.onResizeSubscription();
        window.removeEventListener('resize', this.onResize);
    }
    
    static onResize = () => {
        if (this.renderer) {
            this.renderer.setSize(this.panel.offsetWidth, this.panel.offsetHeight);
            this.renderer.setPixelRatio(this.panel.devicePixelRatio);
        
            this.camera.aspect = this.panel.offsetWidth / this.panel.offsetHeight;
            this.camera.updateProjectionMatrix();
        }
    }
    
    static updateJoints = (jointData) => {
        if (this.scene) {
            const sceneRobot = this.scene.getObjectByName("base");
            if (sceneRobot) {
                for (let i = 0; i < 12; i++) {
                    const joint = jointData[i]
                    sceneRobot.joints[joint.name].setJointValue(joint.position);
                }
            }
        }
    }
    
    static render = () => {
        if (this.renderer) {
            requestAnimationFrame(this.render);
            this.renderer.render(this.scene, this.camera);
        }
    }
}

export default RobotModel;
