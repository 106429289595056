import React from "react";
import { toast } from 'react-toastify';
import { Subject } from 'rxjs';
import { currentTimeString } from "./utils";

class Notification {
    msg;
    title;
    type;
    timestamp;
    constructor(msg, title, type, timestamp) {
        this.msg = msg;
        this.title = title;
        this.type = type;
        this.timestamp = timestamp;
    }
}

const ToasterMessage = (props) => {
    return (
        <div>
            <h5>{props.title}</h5>
            <p>{props.msg}</p>
        </div>
    )
}

class Notifications {
    static notificationList = [];
    static notificationObservable = new Subject();
    static toasterOptions = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    }

    static info = (msg, title = "Info") => {
        // console.log("Opening toast: " + msg);
        toast.info(< ToasterMessage msg={msg} title={title} />, this.toasterOptions);
        this.notificationList.unshift(new Notification(msg, title, "INFO", currentTimeString()));
        this.notificationObservable.next(this.notificationList);
    }

    static success = (msg, title = "Success") => {
        // console.log("Opening toast: " + msg);
        toast.success(< ToasterMessage msg={msg} title={title} />, this.toasterOptions);
        this.notificationList.unshift(new Notification(msg, title, "SUCCESS", currentTimeString()));
        this.notificationObservable.next(this.notificationList);
    }

    static warn = (msg, title = "Warning") => {
        // console.log("Opening toast: " + msg);
        toast.warn(< ToasterMessage msg={msg} title={title} />, this.toasterOptions);
        this.notificationList.unshift(new Notification(msg, title, "WARN", currentTimeString()));
        this.notificationObservable.next(this.notificationList);
    }

    static error = (msg, title = "Error") => {
        // console.log("Opening toast: " + msg);
        toast.error(< ToasterMessage msg={msg} title={title} />, this.toasterOptions);
        this.notificationList.unshift(new Notification(msg, title, "ERROR", currentTimeString()));
        this.notificationObservable.next(this.notificationList);
    }

    static getNotifications() {
        return this.notificationList;
    }
}

export default Notifications;