import React, { useState } from 'react';
import './Dialog.css'

const DeleteUser = (props) => {

    const close = () => {
        props.close();
    }

    const removeUser = () => {
        props.removeUser();
    }

    return (
        <>
        <div className="backdrop"></div>
        <div className="modal fade show">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleSmallModalScrollableTitle">
                    Delete user?</h5>
                    <button type="button" className="close" onClick={() => close()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => close()}>
                        <span aria-hidden="true">Cancel</span>
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => removeUser()}>
                        <span aria-hidden="true">Delete</span>
                    </button>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default DeleteUser