import React, { useRef, useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RobotMovement from "../../../js/robotmovement";
import { toggleTouchControls } from "../../../actions/index.js";
import './TouchControls.css'

const TouchControls = () => {
    const sideBarView = useSelector(state => state.showSideBar);
    const joystickRightContainer = useRef(null);
    const joystickLeftContainer = useRef(null);
    const showTouchControls = useSelector(state => state.showTouchControls);
    const dispatch = useDispatch();

    useEffect(() => {
        if (showTouchControls) {
            RobotMovement.addJoystickRightToElement(joystickRightContainer.current);
            RobotMovement.addJoystickLeftToElement(joystickLeftContainer.current);
        }

        return () => {
            RobotMovement.destroyJoystickRight();
            RobotMovement.destroyJoystickLeft();
        };
    });

    const closeControls = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        dispatch(toggleTouchControls());
    }

    return !showTouchControls? (
        <div className="touch-controls-icon-container" onClick={() => dispatch(toggleTouchControls())}>
            <i className="material-icons">games</i>
        </div>
    ) : (
        <>
            <div className="touch-controls-container">
                <div className="touch-controls-relative-container">
                    <div className="close-touch-controls" onClick={(e) => closeControls(e)}>
                        <i className="material-icons">close</i>
                    </div>
                    <div className="dropup-container">
                        < DropUp side={"right"}/>
                    </div>
                    <div ref={joystickRightContainer}></div>
                </div>
            </div>
            <div className="touch-controls-container-left">
                <div className="touch-controls-relative-container">
                    <div className="dropup-container">
                        < DropUp side={"left"}/>
                    </div>
                    <div ref={joystickLeftContainer}></div>
                </div>
            </div>
        </>        
    )
}

const DropUp = (props) => {
    const { t } = useTranslation();
    const [listItems, setListItems] = useState([]);
    const [selctedItem, setSelectedItem] = useState();
    
    useEffect(() => {
        const updateJoystickSubscription = RobotMovement.joysticksDropupObservable.subscribe((selectedItems) => {
            if (props.side === "right") {
                setSelectedItem(selectedItems[1]);
            } else {
                setSelectedItem(selectedItems[0]);
            }
        });

        props.side === "right"? setSelectedItem(RobotMovement.joystickRightOption) : setSelectedItem(RobotMovement.joystickLeftOption);

        const tmpItems = RobotMovement.getJoystickOptions();
        console.log(tmpItems)
        if (tmpItems.length > 0) {
            setListItems(tmpItems);
        }

        return () => {
            updateJoystickSubscription.unsubscribe();
        }

    }, [props.side]);
    
    const switchJoysticOption = (option) => {
        if (props.side === "right") {
            RobotMovement.setJoystickRightOption(option);
        } else {
            RobotMovement.setJoystickLeftOption(option);
        }
    }

    return (
        <div className="dropup">
            <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t(selctedItem)}
            </button>
            <div className="dropdown-menu">
                {listItems.map((option, index) => (
                    <li className="dropdown-item clickable" key={index} onClick={() => switchJoysticOption(option)}>{t(option)}</li>
                ))}
            </div>
        </div>
    )
}

export default TouchControls;