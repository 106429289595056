import React, { useRef, useEffect, useState}  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RobotConnection from "../../../js/robotconnection.js";
import RobotMovement from "../../../js/robotmovement";
import Panels from "../../../js/panels";
import { freezePanel } from "../../../actions/index.js";
import WebGLModelPanel from "./WebGLModelPanel";
import WebGLCameraPanel from "./WebGLCameraPanel";
import './VideoPanel.css'

const VideoPanel = (props) => {
    const remoteVideo = useRef(null);
    const dispatch = useDispatch();
    const [panelType, setPanelType] = useState(Panels.getPanelType(props.id));

    useEffect(() => {
        if (panelType === 'video') {
            Panels.setRemoteStream(props.id, remoteVideo.current);
        }

        const refreshSubscription = Panels.refreshPanels.subscribe(() => {
            setPanelType(Panels.getPanelType(props.id));
        });

        return () => {
            refreshSubscription.unsubscribe();
        }
    });

    const changeStream = (videoStr) => {
        if (videoStr === '3d_model') {
            setPanelType('3d_model');
        }
        else if (videoStr === 'spot_front') {
            setPanelType('3d_camera');
        }
        else {
            setPanelType('video');
            dispatch(freezePanel(props.id));
        }
        Panels.requestVideo(videoStr, Panels.panelData[props.id].stream, props.id);
    } 

    console.log("Opening new video panel " + props.id);

    return (
        
        <div id="videoPanelContainer" className="video-panel-container">
            { panelType === 'video'?
                [
                < VideoBufferOverlay id={props.id} key={"VideoBufferOverlay"}/>,
                <video ref={remoteVideo} id="remoteVideo" className="video" autoPlay playsInline key={"video"}></video>,
                < PanelDropdown id={props.id} changeStream={(item) => changeStream(item)} key={"PanelDropdown"}/>,
                < FrameRate id={props.id} key={"FrameRate"}/>,
                < PtzControls id={props.id} key={"PtzControls"}/>
                ]
            : null }
            { panelType === '3d_model' ?
                [
                < PanelDropdown id={props.id} changeStream={(item) => changeStream(item)} key={"PanelDropdown"}/>,
                < WebGLModelPanel key={"WebGLModelPanel"}/>
                ]
            : null }
            { panelType === '3d_camera' ?
                [
                < VideoBufferOverlay id={props.id} key={"VideoBufferOverlay"}/>,
                < PanelDropdown id={props.id} changeStream={(item) => changeStream(item)} key={"PanelDropdown"}/>,
                < WebGLCameraPanel id={props.id} key={"WebGLCameraPanel"}/>
                ]
            : null }
        </div>        
    )
}

const VideoBufferOverlay = (props) => {
    const frozenPanels = useSelector(state => state.frozenPanels.items);
    const showOverlay = frozenPanels.includes(props.id);

    return (
        showOverlay?
            <div className="video-panel-overlay">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        : ""
    )
}

const PanelDropdown = (props) => {
    const { t } = useTranslation();
    const [listItems, setListItems] = useState([]);
    const name = Panels.getPanelStreamLabel(props.id);
    const displayName = name !== ""? name : "Select Stream";

    useEffect(() => {
        const videoListSubscription = Panels.videoListObservable.subscribe(newList => {
            setListItems(newList);
        });

        const tmpItems = Panels.getAvailableVideoList();
        if (tmpItems.length > 0) {
            setListItems(tmpItems);
        }

        return () => {
            videoListSubscription.unsubscribe();
        };
    }, []);

    return (
        <div className="stream-selection-container">
            <div className="dropdown">
                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {t(displayName)}
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    {listItems.map((item, index) => (
                        <li className="dropdown-item clickable" key={index} onClick={() => props.changeStream(item)}>{t(item)}</li>
                    ))}
                </div>
            </div>
        </div>
    )
}

const FrameRate = (props) => {
    const [framerate, setFramerate] = useState("");

    useEffect(() => {
        const framerateSubscription = Panels.framrateObservable.subscribe((framerates) => {
            setFramerate(framerates[props.id]);
        });

        return () => {
            framerateSubscription.unsubscribe();
        };
    })

    return (
        <div className="framerate-container">
            {framerate + " fps"}
        </div>
    )
}

const PtzControls = (props) => {
    const [name, setName] = useState(Panels.getPanelStreamLabel(props.id));
    const showTouchControls = useSelector(state => state.showTouchControls);

    useEffect(() => {
        const videoListSubscription = Panels.videoListObservable.subscribe(() => {
            setName(Panels.getPanelStreamLabel(props.id));
        });

        return () => {
            videoListSubscription.unsubscribe();
        };
    }, [props.id]);

    return (
        name === "ptz" && !showTouchControls?
            <div className="ptz-controls-container">
                <div className="ptz-controls-content">
                    <div></div>
                    < PtzControlKey startKey={'i'} stopKey={' '} icon={'expand_less'}/>
                    <div></div>
                    < PtzControlKey startKey={'j'} stopKey={' '} icon={'chevron_left'}/>
                    < PtzControlKey startKey={'m'} stopKey={null} icon={'home'}/>
                    < PtzControlKey startKey={'l'} stopKey={' '} icon={'chevron_right'}/>
                    < PtzControlKey startKey={'u'} stopKey={'.'} icon={'add'}/>
                    < PtzControlKey startKey={'k'} stopKey={' '} icon={'expand_more'}/>
                    < PtzControlKey startKey={'o'} stopKey={'.'} icon={'remove'}/>
                </div>
            </div>
        : ""
    )
}

const PtzControlKey = (props) => {
    let mousedown = false;

    const ptzMove = (key) => {
        mousedown = true;
        RobotConnection.sendCommand(key);
        RobotMovement.addToUsedCommands(key);
    }

    const ptzMoveStop = (key) => {
        if (mousedown && key) {
            RobotConnection.sendCommand(key);
            RobotMovement.addToUsedCommands(key);
            mousedown = false;
        }
    }
    return (
        <button className="btn btn-icon-only btn-tertiary ptz-control-button" 
            onMouseDown={() => ptzMove(props.startKey)} onMouseUp={() => ptzMoveStop(props.stopKey)} onMouseLeave={() => ptzMoveStop(props.stopKey)}>
            <i className="modus-icon material-icons">{props.icon}</i>
        </button>
    )
}

export default VideoPanel;