import React, { useRef, useEffect }  from 'react';
import RobotFrontCamera from "../../../js/robotfrontcamera";

const WebGLCameraPanel = (props) => {
    const ref = useRef(null)

    useEffect(() => {
        RobotFrontCamera.init(ref.current, props.id);
    }, []);
    
    return (
        <div ref={ref} className="viewer-container"></div>
    )
}

export default WebGLCameraPanel;