import React from 'react';
import { useSelector } from 'react-redux';
import './Content.css';
import ControllerView from './Views/ControllerView/ControllerView';
import NotificationLog from "./Views/DialogViews/NotificationLog";
import StartView from './Views/StartVIew/StartView';
// import AdjustDialog from "./Views/DialogViews/AdjustDialog";

const Content = () => {
    const showSelectionView = useSelector(state => !state.connected);
    const showControllerView = useSelector(state => state.connected);

    return (
        <div className="main-container">
            { showSelectionView ? < StartView /> : null }
            { showControllerView ? < ControllerView /> : null }
            {/* { showControllerView ? < AdjustDialog /> : null } */}
            < NotificationLog/>
        </div>
    );
}

export default Content;
