import { getAccessToken, getUserTid, getUserEmail } from "./utils.js";

const axios = require('axios');

const endpoint = "http://trimblespot.eastus.cloudapp.azure.com:5001";

export default class Requests {

    config;
    userTid;
    user;

    static initRequests = async () => {
        await this.setAuthorization();
        await this.setUserTid();
        await this.getUser().then(res => {
            this.user = res.data;
            this.config.params = {
                requestingUserId: this.user.id
            }
        });
        return this.user;
    }

    static setAuthorization = async () => {
        const token = getAccessToken();
        this.config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
    }

    static setUserTid = async () => {
        this.userTid = getUserTid();
    }

    static getUser = async () => {
        try {
            let response = await axios.get(endpoint + '/user' + this.userTid, this.config);
            if (response.data === "") {
                console.log("Handle empty response");
                const email = getUserEmail();
                response = await axios.get(endpoint + '/user' + email, this.config);
                if (response.data !== "") {
                    console.log("User found by email, update email to id");
                    const updatedUserData = {
                        "trimbleId": this.userTid
                    }
                    await axios.post(endpoint + '/user' + email, updatedUserData, this.config);
                    response = await axios.get(endpoint + '/user' + this.userTid, this.config);
                }
            }
            // console.log(response);
            return response
        } catch (error) {
            console.error(error);
        }
    }

    static getUserRobots = async (userId) => {
        try {
            const response = await axios.get(endpoint + '/access/robots' + userId, this.config);
            return response
        } catch (error) {
            console.error(error);
        }
    }

    static getRobotUsers = async (robotId) => {
        try {
            const response = await axios.get(endpoint + '/access/users' + robotId, this.config);
            return response
        } catch (error) {
            console.error(error);
        }
    }

    static getCompany = async () => {
        try {
          const response = await axios.get(endpoint + '/company' + this.user.company.id, this.config);
          return response
        } catch (error) {
          console.error(error);
        }
    }

    static getCompanies = async () => {
        try {
          const response = await axios.get(endpoint + '/companies', this.config);
          return response
        } catch (error) {
          console.error(error);
        }
    }

    static postCompany = async (newCompany) => {
        const companyData = {
            name: newCompany
        }
        try {
            const response = await axios.post(endpoint + '/company', companyData, this.config);
            return response;
        } catch(error) {
            console.error(error)
        }
    }

    static postRobots = async (newRobot) => {
        const robotData = {
            name: newRobot.name,
            companyId: newRobot.companyId,
            serial: newRobot.serial
        };
        try {
            const response = await axios.post(endpoint + '/robot', robotData, this.config);
            console.log(response);
            return response;
        } catch(error) {
            console.error(error)
        }
    }

    static postUsers = async (newUser) => {
        const userData = {
            name: newUser.userName,
            trimbleId: newUser.trimbleId,
            companyId: newUser.companyId,
            companyAdmin: newUser.companyAdmin,
            trimbleAdmin: newUser.trimbleAdmin
        };
        try {
            const response = await axios.post(endpoint + '/user', userData, this.config);
            return response;
        } catch(error) {
            console.error(error)
        }
    }

    static postAccess = async (userId, robotId) => {
        const accessData = {
            userId: userId,
            robotId: robotId
        }
        try {
            const response = await axios.post(endpoint + '/access', accessData, this.config);
            return response;
        } catch(error) {
            console.error(error)
        }
    }

    static deleteCompany = async (companyId) => {
        try {
            const response = await axios.delete(endpoint + '/company' + companyId, this.config);
            return response;
        } catch(error) {
            console.error(error)
        }
    }

    static deleteRobot = async (robotId) => {
        try {
            const response = await axios.delete(endpoint + '/robot' + robotId, this.config);
            return response;
        } catch(error) {
            console.error(error)
        }
    }

    static deleteUser = async (userId) => {
        try {
            const response = await axios.delete(endpoint + '/user' + userId, this.config);
            return response;
        } catch(error) {
            console.error(error)
        }
    }

    static getRobotsForUser = async () => {
        try {
            const response = await axios.get(endpoint + '/access/robots' + this.user.id, this.config);
            return response;
        } catch(error) {
            console.error(error);
        }
    }
}