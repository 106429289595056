import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAdminView } from "../../actions/index";
import { setAdminViewContext } from '../../actions/index';
import Requests from '../../js/requests';
import AddRobot from '../DialogViews/AddRobot';
import AddUser from '../DialogViews/AddUser'
import Notifications from "../../js/notifications";
import DeleteRobot from '../DialogViews/DeleteRobot';
import DeleteUser from '../DialogViews/DeleteUser';


import './PermissionView.css';

const PermissionView = () => {
    const company = useSelector(state => state.selectedCompany);
    const permissionLevel = useSelector(state => state.permissionLevel);
    const [companyRobots, setCompanyRobots] = useState(company.companyRobots);
    const [companyUsers, setCompanyUsers] = useState(company.companyUsers);
    const [activeUser, setActiveUser] = useState(null);
    const [activeRobot, setActiveRobot] = useState(null);
    const [showAddRobot, setShowAddRobot] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [showDeleteRobot, setShowDeleteRobot] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [userRobots, setUserRobots] = useState([]);
    const [robotUsers, setRobotUsers] = useState([]);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [robotIdToDelete, setRobotIdToDelete] = useState(null);
    const dispatch = useDispatch();

    const fetchUserRobots = (userId) => {
        // console.log(userId);
        Requests.getUserRobots(userId)
        .then(res => {
            try {
                setUserRobots(res.data);
                // console.log(res.data);
            } catch (error) {
                console.log(error);
            }
        });
    }

    const fetchRobotUsers = (robotId) => {
        // console.log(robotId);
        Requests.getRobotUsers(robotId)
        .then(res => {
            try {
                setRobotUsers(res.data)
                // console.log(res.data);
            } catch (error) {
                console.log(error);
            }
        });
    }

    const goBack = () => {
        dispatch(setAdminViewContext("ADMIN_VIEW"));
    };

    const selectUser = (user) => {
        setActiveRobot(null);
        setActiveUser(user);
        fetchUserRobots(user.id);
    }

    const selectRobot = (robot) => {
        setActiveUser(null);
        setActiveRobot(robot);
        fetchRobotUsers(robot.id);
    }

    const userActive = (companyUser) => {
        if (activeUser === null)
            return false;
        return companyUser.id === activeUser.id;
    }

    const robotActive = (companyRobot) => {
        if (activeRobot === null)
            return false;
        return companyRobot.name === activeRobot.name;
    }

    const openAddRobot = () => {
        setShowAddRobot(true)
    }

    const closeAddRobot = () => {
        setShowAddRobot(false)
    }

    const openAddUser = () => {
        setShowAddUser(true)
    }

    const closeAddUser = () => {
        setShowAddUser(false)
    }

    const addRobot = (state) => {
        if (state.name === "" || state.serial === "") {
            Notifications.error("Fill in all fields")
        } else {
            state.companyId = company.id
            Requests.postRobots(state)
            .then(res => {
                setCompanyRobots([...companyRobots, res.data]);
                setShowAddRobot(false);
            });
        }
    }

    const addUser = (state) => {
        console.log(state)
        if (state.userName === "" || state.trimbleId === "") {
            Notifications.error("Fill in all fields")
        } else {
            state.companyId = company.id
            Requests.postUsers(state)
            .then(res => {
                setCompanyUsers([...companyUsers, res.data]);
                setShowAddUser(false);
            });
        }
    }

    const userHasAccessToRobot = (robotId) => {
        const robot = userRobots.find(robot => {
            return robot.id === robotId
        });
        if (robot) {
            return true;
        } else {
            return false;
        }
    }

    const robotHasAccessToUser = (userId) => {
        const user = robotUsers.find(user => {
            return user.id === userId;
        });
        if (user) {
            return true;
        } else {
            return false;
        }
    }

    const postAccessToRobot = (robotId) => {
        Requests.postAccess(activeUser.id, robotId)
        .then((res) => {
            fetchUserRobots(activeUser.id);
        });
    }

    const postAccessToUser = (userId) => {
        Requests.postAccess(userId, activeRobot.id)
        .then((res) => {
            fetchRobotUsers(activeRobot.id);
        });
    }

    const openDeleteUser = (e, userId) => {
        e.stopPropagation()
        setUserIdToDelete(userId)
        setShowDeleteUser(true)
    }

    const closeDeleteUser = () => {
        setShowDeleteUser(false)
    }

    const removeUser = () => {
        Requests.deleteUser(userIdToDelete)
        .then(res => {
            setCompanyUsers(companyUsers.filter(user => user.id !== userIdToDelete));
        })
        closeDeleteUser()
    }

    const openDeleteRobot = (e, robotId) => {
        e.stopPropagation()
        setRobotIdToDelete(robotId)
        setShowDeleteRobot(true)
    }

    const closeDeleteRobot = () => {
        setShowDeleteRobot(false)
    }

    const removeRobot = () => {
        Requests.deleteRobot(robotIdToDelete)
        .then(res => {
            setCompanyRobots(companyRobots.filter(robot => robot.id !== robotIdToDelete));
        })
        closeDeleteRobot()
    }

    return (
        <>
        <div className="d-flex justify-content-center position-relative mt-3 mb-3">
            {(permissionLevel > 1)?
                <button className="btn btn-outline-dark back-buttton" onClick={() => goBack()}>
                    <i className="modus-icon material-icons left-icon">arrow_back</i>
                Back
            </button>
            : null 
            }
            <h1 className="m-0">{company.name}</h1>
        </div>
        <div className="company-container">
            <div className="company-content mr-3">
                <h2 className="mb-1 mt-5">Users</h2>
                <div className="mt-3 mb-3">
                    {companyUsers.map((companyUser, index) => {
                        return (
                            <li key={index} className={"list-group-item clickable list-item-right-control " + (userActive(companyUser)? "active " : "") + (activeRobot !== null? "list-item-leftright-control" : "")}
                                onClick={() => selectUser(companyUser)}>
                                {activeRobot !== null?
                                    <div
                                        className="custom-control custom-checkbox"
                                        onClick={(e) => e.stopPropagation()}>
                                        <input
                                            type="checkbox"
                                            checked={robotHasAccessToUser(companyUser.id)}
                                            className="custom-control-input"
                                            id={companyUser.id}
                                            onChange={() => postAccessToUser(companyUser.id)}></input>
                                        <label
                                            className="custom-control-label"
                                            htmlFor={companyUser.id}>
                                        </label>
                                    </div>
                                : null
                                }
                                <span>{companyUser.name}</span>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <button className="btn btn-icon-only btn-text-primary d-flex justify-content-center align-items-center" onClick={(e) => openDeleteUser(e, companyUser.id)}>
                                        <i className="material-icons">delete</i>
                                    </button>
                                </div>
                            </li>
                        )
                    })}
                </div>
                <div className="d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary" onClick={() => openAddUser()}>
                        Add
                    </button>
                </div>
                {showAddUser ? <AddUser close={() => closeAddUser()} add={(state) => addUser(state)}  /> : null}
                {showDeleteUser ? <DeleteUser close={() => closeDeleteUser()} removeUser={() => removeUser()}/> : null}
            </div>
            <div className="company-content ml-3">
                <h2 className="mb-1 mt-5">Robots</h2>
                <div className="mt-3 mb-3">
                    {companyRobots.map((companyRobot, index) => {
                        return (
                            <li key={index} className={"list-group-item clickable list-item-right-control " + (robotActive(companyRobot)? "active " : "") + (activeUser !== null? "list-item-leftright-control" : "")}
                                onClick={() => selectRobot(companyRobot)}>
                                {activeUser !== null?
                                    <div
                                        className="custom-control custom-checkbox"
                                        onClick={(e) => e.stopPropagation()}>
                                        <input
                                            type="checkbox"
                                            checked={userHasAccessToRobot(companyRobot.id)}
                                            className="custom-control-input"
                                            id={companyRobot.id}
                                            onChange={() => postAccessToRobot(companyRobot.id)}></input>
                                        <label
                                            className="custom-control-label"
                                            htmlFor={companyRobot.id}>
                                        </label>
                                    </div>
                                : null
                                }
                                <span>{companyRobot.name}, {companyRobot.serial}</span>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <button className="btn btn-icon-only btn-text-primary d-flex justify-content-center align-items-center" onClick={(e) => openDeleteRobot(e, companyRobot.id)}>
                                        <i className="material-icons">delete</i>
                                    </button>
                                </div>
                            </li>
                        )
                    })}
                </div>
                <div className="d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary" onClick={() => openAddRobot()}>
                        Add
                    </button>
                </div>
                {showAddRobot ? <AddRobot close={() => closeAddRobot()} add={(state) => addRobot(state)}  /> : null}
                {showDeleteRobot ? <DeleteRobot close={() => closeDeleteRobot()} removeRobot={() => removeRobot()}/> : null}
            </div>
        </div>
        {/* {activeUser !== null?
            <h2 className="mt-4">User Information</h2>
        : null
        }
        {activeRobot !== null?
            <h2 className="mt-4">Robot Information</h2>
        : null
        } */}
        </>
    )
}

export default PermissionView;