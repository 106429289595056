import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAdminViewContext} from '../../actions';
import { setActiveCompany } from '../../actions';
import Requests from '../../js/requests';
import { toggleAdminView } from "../../actions/index";
import Notifications from "../../js/notifications";

import './AdminView.css';
import AddCompany from '../DialogViews/AddCompany';
import DeleteCompany from '../DialogViews/DeleteCompany';
import { constants } from 'buffer';

const CompanyView = () => {
    const [companies, setCompanies] = useState([]);
    const [showAddCompany, setShowAddCompany] = useState(false);
    const [showDeleteCompany, setShowDeleteCompany] = useState(false)
    const [companyIdToDelete, setCompanyIdToDelete] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = () => {
        Requests.getCompanies().then((res) => {
            // console.log(res);
            setCompanies(res.data);
        });
    }

    const openCompanyView = (company) => {
        dispatch(setActiveCompany(company));
        dispatch(setAdminViewContext("PERMISSION_VIEW"));
    };

    const handleCloseAdminClick = () => {
        dispatch(toggleAdminView());
    }

    const openAddCompany = () => {
        setShowAddCompany(true)
    }

    const closeAddCompany = () => {
        setShowAddCompany(false)
    }

    const openDeleteCompany = (e, companyId) => {
        e.stopPropagation();
        setCompanyIdToDelete(companyId);
        setShowDeleteCompany(true)
    }

    const closeDeleteCompany = () => {
        setShowDeleteCompany(false)
    }

    const addCompany = (companyName) => {
        if (companyName === ""){
            Notifications.error("Empty company name")
        } else {
            Requests.postCompany(companyName)
            .then(res => {
                setCompanies([...companies, res.data]);
                setShowAddCompany(false);
            });
        }
    }

    const removeCompany = () => {
        Requests.deleteCompany(companyIdToDelete)
        .then(res => {
            if (res.data) {
                setCompanies(companies.filter(company => company.id !== companyIdToDelete));
            }
        })
        closeDeleteCompany()
    }

    return (
        <>
        <h2 className="mb-4 mt-4">Companies</h2>
        <div className="admin-content-body">
            <ul className="list-group">
                {companies.map((company) => {
                        return (
                            <li key={company.name} className="list-group-item list-item-right-control" onClick={() => openCompanyView(company)}>
                                {company.name}
                                <div className="custom-control custom-switch custom-control-inline">
                                    <button className="btn btn-icon-only btn-text-primary d-flex justify-content-center align-items-center" onClick={(e) => openDeleteCompany(e, company.id)}>
                                        <i className="material-icons">delete</i>
                                    </button>
                                </div>
                            </li>
                        )
                    })}
            </ul>
        </div>
        {showDeleteCompany ? <DeleteCompany close={() => closeDeleteCompany()} remove={() => removeCompany()}/> : null}
        <div className="d-flex justify-content-end mt-3">
            <button type="submit" className="btn btn-primary" onClick={() => openAddCompany()}>
                Add Company
            </button>
        </div>
        <div>
            {showAddCompany ? <AddCompany close={() => closeAddCompany()} add={(name) => addCompany(name)}  /> : null}
        </div>
        </>
    )
}

export default CompanyView;