import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RobotConnection from "../../js/robotconnection.js";
import { localStorageGet, localStorageRemove } from "../../js/utils";
import { gatheringIce, toggleAdminView } from "../../actions/index";
import Requests from '../../js/requests.js';
import './SelectionView.css';

const SelectionView = () => {
    const { t } = useTranslation();
    const showSpinner = useSelector(state => state.gatheringIceCandidate);
    const [userRobots, setUserRobots] = useState([]);
    const dispatch = useDispatch();
    
    useEffect(() => {
        getRobot();
    }, [])

    const cancelConnection = () => {
        RobotConnection.closeConnection();
    }

    const connectToSerial = (serial) => {
        RobotConnection.serial = serial;
        RobotConnection.startConnection();
        dispatch(gatheringIce());
    }

    const getRobot = () => {
        Requests.getRobotsForUser()
        .then(res => {
            setUserRobots(res.data);
        });
    }

    return (
        <>
            <div className="selection-content">
                <div className="selection-content-header border-bottom">
                    {showSpinner?
                        <p className="m-0">{t("CONNECTING_TO_ROBOT")}...</p>
                        : <h2 className="m-0">User Robots</h2>
                    }
                    {showSpinner?
                        <div className="spinner-container">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <button className="btn btn-icon-only btn-text-dark" onClick={() => { cancelConnection() }}>
                                <i className="modus-icon material-icons">close</i>
                            </button>
                        </div>
                    : null
                    }
                </div>
                <div className="selection-content-body">
                    {(userRobots.length > 0)?
                        !showSpinner?
                        userRobots.map((robot, index) => (
                            <li key={index} className="list-group-item justify-content-between" onClick={() => connectToSerial(robot.robotId)}>
                                <span>{robot.name}, {robot.robotId}</span>
                            </li>
                        ))
                        : null
                    :
                    !showSpinner?
                        <div className="list-placeholder-container">
                            <p className="m-0">No robots are assigned to this user</p>
                        </div>
                    : null
                    }
                </div>
            </div>
        </>
    );
}

export default SelectionView;