import { Subject } from "rxjs";
import VideoPanelData from "./videopaneldata.js";
import RobotConnection from "./robotconnection.js";
import RobotMovement from "./robotmovement.js";
import store from "../index.js";


class Panels {
    static videoList = [];
    static availableVideoList = [];
    static videoListObservable = new Subject();
    static fullscreenObservable = new Subject();
    static refreshPanels = new Subject();
    static otherPanels = ['3d_model'] //['3d'];
    static fullscreenElement;
    static updateTimeout;

    static panelData = {
        0: new VideoPanelData(0, "frame1"),
        1: new VideoPanelData(1, "frame2"),
        2: new VideoPanelData(2, "frame3"),
        3: new VideoPanelData(3, "frame4")
    }

    // For fixing the video stream switching
    static framrateFetchingLoop;
    static framrateObservable = new Subject();

    static resetOnClose = () => {
        for (let i = 0; i < 4; i++) {
            this.panelData[i].stream = "";
            if (this.panelData[i].media) {
                let videoTrack = this.panelData[i].media.getVideoTracks();
                if (videoTrack.length > 0) {
                    this.panelData[i].media.removeTrack(videoTrack[0]);
                }
                this.panelData[i].media = null;
            }
        }
        this.videoList = [];
        this.availableVideoList = [];
    }

    static getAvailableVideoList() {
        return this.availableVideoList;
    }

    static getPanelStreamLabel(id) {
        return this.panelData[id].stream;
    }

    static setRemoteStream(id, videoElement) {
        this.panelData[id].addRef(videoElement);
    };

    static initializePanelLists(list) {
        this.videoList = list;
        this.videoList = this.videoList.concat(this.otherPanels);
        this.panelData[0].stream = this.videoList[0];
        for (let i = 1; i < this.videoList.length; i++) {
            this.availableVideoList.push(this.videoList[i]);
        }
        //console.log(this.panelData);
        if (this.videoList.includes("ptz")) {
            console.log("adding ptz controls");
            RobotMovement.addPtzControls();
        }
        this.refreshPanels.next();
        this.videoListObservable.next(this.availableVideoList);
    }

    static requestVideo(videoStart, videoStop, id) {
        if (!this.otherPanels.includes(videoStart)) {
            let msg;
            let type;
            if (videoStop !== "" && !this.otherPanels.includes(videoStop)) {
                msg = {
                    "VideoSourceToStart": videoStart,
                    "VideoSourceToStop": videoStop,
                    "Frame": this.panelData[id].label
                }
                type = 2;
            } else {
                msg = {
                    "VideoSourceToStart": videoStart,
                    "Frame": this.panelData[id].label
                }
                type = 1;
            }
            const fullmsg = {
                "MsgType": type,
                "Message": JSON.stringify(msg)
            }
            this.panelData[id].startFrameRateListen();
            console.log(JSON.stringify(fullmsg));
            RobotConnection.dc.send(JSON.stringify(fullmsg));
        } else {
            console.log("closing panel " + videoStop);
            this.stopVideo(videoStop, id);
        }
        this.updateVideoLists(videoStart, videoStop, id);
    }

    static updateVideoLists(videoStart, videoStop, id) {
        this.panelData[id].stream = videoStart;
        const videoListCopy = this.availableVideoList;
        this.availableVideoList = [];
        for (let i = 0; i < this.videoList.length; i++) {
            if ((this.videoList[i] !== videoStart) && (videoListCopy.includes(this.videoList[i]) || this.videoList[i] === videoStop)) {
                this.availableVideoList.push(this.videoList[i]);
            } else if (videoStart === "none") {
                this.availableVideoList.push(this.videoList[i]);
            }
        }

        this.videoListObservable.next(this.availableVideoList);
    }

    static startFramerateFetching() {
        this.framrateFetchingLoop = setInterval(() => {
            const framerateList = []
            for (const panel in this.panelData) {
                framerateList.push(this.panelData[panel].getFrameRate());
            }
            this.framrateObservable.next(framerateList);
        }, 100)
    }

    static endFramerateFetching() {
        clearInterval(this.framrateFetchingLoop);
        this.framrateFetchingLoop = null;
    }

    static closePanelHandler(numPanel) {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }
        this.updateTimeout = setTimeout(() => {
            for (let i = 0; i < 4; i++) {
                if (i > (numPanel - 1) && this.panelData[i].stream !== "") {
                    this.stopVideo(this.panelData[i].stream, i);
                }
            } 
        }, 10000);
    }

    static stopVideo(sourceToStop, id) {
        if (!this.otherPanels.includes(sourceToStop)) {
            console.log("Closing: " + this.panelData[id].label + ", " + sourceToStop);
            const type = 3;
            const msg = {
                "VideoSourceToStop": sourceToStop,
                "Frame": this.panelData[id].label
            }
            const fullmsg = {
                "MsgType": type,
                "Message": JSON.stringify(msg)
            }
            console.log(JSON.stringify(fullmsg));
            RobotConnection.dc.send(JSON.stringify(fullmsg));
        }
        this.updateVideoLists("", sourceToStop, id);
    }

    static fullcreenModeChanged = () => {
        console.log(document.fullscreenElement);
        if (document.fullscreenElement) {
            this.fullscreenObservable.next(true);
        } else {
            this.fullscreenObservable.next(false);
        }   
    }

    static openFullscreen = (element) => {
        this.fullscreenElement = element;
        this.fullscreenElement.requestFullscreen().then(() => {
            document.addEventListener('fullscreenchange', this.fullcreenModeChanged);
        });
    }

    static closeFullscreen = async () => {
        document.exitFullscreen().then(() => {
            this.fullcreenModeChanged();
            document.removeEventListener('fullscreenchange', this.fullcreenModeChanged);
        });
    }

    static handleEscPress = () => {
        document.removeEventListener('fullscreenchange', this.fullcreenModeChanged);
        this.fullscreenObservable.next(false);
    }

    static isStreamOpen = (stream) => {
        let rValue = false;
        for (let panel in this.panelData) {
            if (this.panelData[panel].stream === stream && store.getState().panelView > panel) {
                rValue = true;
            }
        }
        return rValue;
    }

    static getPanelType = (id) => {
        const stream = this.panelData[id].stream
        switch (stream) {
            case "3d_model":
                return "3d_model";
            case "spot_front":
                return "3d_camera";
            default:
                return "video";
        }
    }
}

export default Panels;