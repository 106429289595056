import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux';
import allReducers from './reducers/index.js';
import { Provider } from "react-redux";
import { login } from './auth/authentication';
import { loginAction, setPermissionLevel } from "./actions/index";
import './i18n';
import Requests from './js/requests';

const store = createStore(allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

(async () => {
  console.log("logging in");
  await login();
  console.log("logged in");
  Requests.initRequests()
  .then((user) => {
    if (user) {
      let permissionLevel = 0;
      if (user.companyAdmin) {
        permissionLevel += 1;
      }
      if (user.trimbleAdmin) {
        permissionLevel += 2;
      }
      store.dispatch(setPermissionLevel(permissionLevel));
      store.dispatch(loginAction());
    } else {
      console.log("!!! Error while fething user information !!!");
    }
  });
  
})();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default store;
