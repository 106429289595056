import store from "../index.js";
import { unfreezePanel } from "../actions/index.js";

class VideoPanelData {
    id = null;
    label = "";
    stream = "";
    media = new MediaStream();
    media2 = new MediaStream();
    ref = null
    timer = null;
    videoFroze = false;
    successfullFrames = 0;

    constructor(id, label) {
        this.id = id;
        this.label = label;
    }

    addTrackToPanel(track) {
        this.media = new MediaStream();
        this.media.addTrack(track, this.media);
    }

    addSecondTrackToPanel(track) {
        this.media2 = new MediaStream();
        this.media2.addTrack(track, this.media2);
    }

    startFrameRateListen() {
        if (this.media && this.media.getTracks().length > 0) {
            console.log("STARTING TIMER");
            this.successfullFrames = 0;
            this.timer = setInterval(() => {
                const frameRate = this.media.getTracks()[0].getSettings().frameRate;
                if (this.videoFroze) {
                    if (frameRate > 0) {
                        this.videoFroze = false;
                        store.dispatch(unfreezePanel(this.id));
                        this.clearTimer();
                    }
                } else {
                    if (frameRate === 0) {
                        this.videoFroze = true;
                    } else {
                        if (this.successfullFrames > 3) {
                            this.videoFroze = false;
                            store.dispatch(unfreezePanel(this.id));
                            this.clearTimer();
                        }
                        this.successfullFrames += 1
                    }
                }
            }, 500);
        }
    }

    getFrameRate() {
        if (this.media && this.media.getTracks().length > 0) {
            return Math.round((this.media.getTracks()[0].getSettings().frameRate + Number.EPSILON) * 100) / 100;
        } else {
            return null;
        }
    }

    addRef(refToAdd) {
        this.ref = refToAdd;
        this.ref.srcObject = this.media;
    }

    clearTimer() {
        console.log("ENDING TIMER");
        clearInterval(this.timer);
        this.timer = null;
    }
}

export default VideoPanelData;