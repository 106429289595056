import React, { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import './Dialog.css'

const endpoint = "http://localhost:5001";

const AddUser = (props) => {
    
    const [state, setState] = useState({
        userName: "",
        trimbleId: "",
        companyId: "",
        companyAdmin: false,
        trimbleAdmin: false
    });

    const changeHandler = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });
    }

    const isChecked = (e) => {
        const value = e.target.checked;
        setState({
            ...state,
            [e.target.name]: value
        })
    }

    const permissionLevel = useSelector(state => state.permissionLevel);

    const close = () => {
        props.close();
    }

    const AddRobot = () => {
        props.add(state);
    };

    return (
        <>
        <div className="backdrop"></div>
        <div className="modal fade show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modal-title-1">
                            Add user</h5>
                        <button type="button" className="close" onClick={() => close()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label htmlFor="userName">Name</label>
                        <input 
                            className="form-control"
                            id="userName"
                            placeholder="Name"
                            type="text"
                            name="userName"
                            value={state.userName}
                            onChange={(e) => changeHandler(e)}
                        />
                        <label htmlFor="trimbleId">Email</label>
                        <input 
                            className="form-control"
                            id="trimbleId"
                            placeholder="Email"
                            type="text"
                            name="trimbleId"
                            value={state.trimbleId}
                            onChange={(e) => changeHandler(e)}
                        />
                    </div>
                    <div className="form-group mb-0">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={state.companyAdmin}
                                id="companyAdmin"
                                name="companyAdmin"
                                onChange={(e) => isChecked(e)}
                            />
                            <label className="custom-control-label" htmlFor="companyAdmin">Company admin</label>
                        </div>
                        {(permissionLevel > 1) ?
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={state.trimbleAdmin}
                                id="trimbleAdmin"
                                name="trimbleAdmin"
                                onChange={(e) => isChecked(e)}
                            />
                            <label className="custom-control-label" htmlFor="trimbleAdmin">Trimble admin</label>
                        </div>
                        : null}
                    </div>
                </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" onClick={() => close()}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => AddRobot()}>
                            Add User
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default AddUser;