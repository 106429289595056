export const secondsToTimeString = ((secondsString) => {
    const seconds = parseInt(secondsString, 10);
    if (!isNaN(seconds)) {
        return new Date(seconds * 1000).toISOString().substr(11, 8);
    } else {
        return "";
    }
});

export const currentTimeString = (() => {
    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
    const currentDate = new Date();
    return currentDate.toLocaleDateString(undefined, options) + " " + currentDate.toLocaleTimeString();
});

export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const localStorageGet = (key) => {
    return JSON.parse(localStorage.getItem(key));
} 

export const localStorageAddItem = (key, item) => {
    let items = localStorageGet(key);
    if (!items) {
        items = [item];
        localStorage.setItem(key, JSON.stringify(items));
    } else if (!items.includes(item)) {
        items.push(item);
        localStorage.setItem(key, JSON.stringify(items));
    }
     else {
        console.log("Item exists in local storage");
    }
}

export const localStorageRemove = (key, itemToRemove) => {
    let items = localStorageGet(key);
    items = items.filter(item => {return item !== itemToRemove});
    localStorage.setItem(key, JSON.stringify(items));
    return items;
}

export const getAccessToken = () => {
    const ts = localStorageGet("trimble-services");
    return ts.accessToken;
}

export const getUserTid = () => {
    const ts = localStorageGet("trimble-services");
    return ts.user.sub;
}

export const getUserEmail = () => {
    const ts = localStorageGet("trimble-services");
    return ts.user.email;
}